import { gql } from '@apollo/client';
import { useLogoutMutation } from '@src/graphql';
import { useCallback } from 'react';

import { actions } from './authStore';
import { clearStorage } from './localStorageAuth';
import { refreshToken } from './refreshToken';

export const LogoutMutationDocument = gql(`
	mutation Logout {
		account {
			logout 
		}
	}
`);

type UseLogoutReturnValue = () => Promise<void>;

export function useLogout(): UseLogoutReturnValue {
  const [postLogout] = useLogoutMutation();

  const logout = useCallback(async () => {
    await postLogout();
    refreshToken.clear();
    clearStorage();
    actions.setIsValid(false);
    window.location.replace('/login');
  }, [postLogout]);

  return logout;
}
