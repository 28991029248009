import { fetchRefreshToken, TokenDetails, tokenDetailsSchema } from './api';
import { actions } from './authStore';
import { isJwtExpired, parseJwt } from './jwt';
import { getAccessToken, setAccessToken } from './localStorageAuth';

const maxRefreshTime = 1 * 60 * 60 * 1000; // 1 hours.

type LockResult = TokenDetails;

/** Represents the refresh token lock. */
type Lock = {
  /** The refresh token lock promise. */
  readonly promise: Promise<LockResult>;
  /** The refresh token lock promise resolve executor function. */
  readonly resolve: (data: LockResult) => void;
};

/**
 * Automatically refresh tokens. NOTE: ONLY for dev use! in production token
 * will be passed in from Agent Interface
 */
export class RefreshToken {
  /** The refresh token lock. */
  private lock: Lock = this.createLock();
  /** The timeout promise resolve executor function. */
  private timeoutResolve?: () => void;
  /** The timeout handle. */
  private timeoutHandle?: number;
  /** Whether the token is currently being refreshed. */
  private isRefreshing = false;
  /** Whether the refresh loop is scheduled - i.e. running. */
  private isScheduled = false;
  /**
   * We will retry some conditions a few times, keep track of what number of
   * retries we are at
   */
  private retries = 0;

  private async schedule() {
    this.isScheduled = true;

    while (this.isScheduled) {
      this.isRefreshing = true;
      actions.setIsLoading(true);
      try {
        const accessToken = getAccessToken();
        if (!accessToken) {
          this.clear();
          break;
        }

        // Log the user out if the refreshToken is expired
        const isRefreshExpired =
          expiresInMs(accessToken.refreshTokenExpiry) < 5000;

        if (isRefreshExpired) {
          this.clear();
          break;
        }

        const oldJwtPayload = parseJwt(accessToken.token);
        if (expiresInMs(oldJwtPayload.exp * 1000) > 1000) {
          this.lock.resolve(accessToken);
          actions.setIsValid(true);
          actions.setIsLoading(false);
          await this.wait(Math.min(oldJwtPayload.exp * 0.8, maxRefreshTime));
          continue;
        }

        const authToken = await fetchRefreshToken({
          jwtToken: accessToken.token,
          refreshToken: accessToken.refreshToken,
        });

        const validatedToken = tokenDetailsSchema.parse(authToken);
        setAccessToken(validatedToken);

        this.lock.resolve(validatedToken);
        this.retries = 0;
        this.isRefreshing = false;
        actions.setIsValid(true);
        actions.setIsLoading(false);

        const jwtPayload = parseJwt(validatedToken.token);
        await this.wait(Math.min(jwtPayload.exp * 0.8, maxRefreshTime));
      } catch (error) {
        // const exception = error as { response?: unknown; message?: string };
        // if (!exception.response && exception.message === 'Network Error') {
        if (this.retries < 3) {
          // Try again.
          this.retries += 1;
          await this.wait(100);
          continue;
        }
        this.pause();
        actions.setIsValid(false);
        actions.setIsLoading(false);
        break;
      }
    }
  }

  private async wait(time: number): Promise<void> {
    await new Promise<void>((resolve) => {
      this.timeoutResolve = resolve;
      this.timeoutHandle = window.setTimeout(resolve, time);
    });
  }

  start(tokenDetails: TokenDetails): void {
    this.lock.resolve(tokenDetails);
    this.schedule().catch(() => undefined);
  }

  /** Resumes the continuous refresh token updates. */
  resume(): void {
    this.schedule().catch(() => undefined);
  }

  pause(): void {
    this.isScheduled = false;
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
    }
    this.timeoutResolve?.();
    this.lock = this.createLock();
  }

  clear(): void {
    this.pause();
    actions.setIsValid(false);
    actions.setIsLoading(false);
    this.lock.resolve({ token: '', refreshToken: '', refreshTokenExpiry: '' });
  }

  getLock = async (): Promise<LockResult> => {
    const { token } = await this.lock.promise;
    const jwtPayload = parseJwt(token);
    if (!this.isRefreshing && token && isJwtExpired(jwtPayload)) {
      this.lock = this.createLock();
      this.pause();
      this.resume();
    }
    return this.lock.promise;
  };

  /** Creates the lock. */
  private createLock(): Lock {
    let resolve: (data: LockResult) => void = () => undefined;
    const promise = new Promise<LockResult>((lockResolve) => {
      resolve = lockResolve;
    });

    return { promise, resolve };
  }
}

function expiresInMs(tokenExpiry: string | number): number {
  const tokenExpired = new Date(tokenExpiry).getTime() - Date.now();
  return tokenExpired;
}

export const refreshToken = new RefreshToken();
