import { proxy } from 'valtio';

type AuthState = {
  isLoading: boolean;
  isValid?: boolean;
};

export const authStore = proxy<AuthState>({
  isLoading: true,
  isValid: undefined,
});

export const actions = {
  setIsLoading: (isLoading: boolean): void => {
    authStore.isLoading = isLoading;
  },
  setIsValid: (isValid: boolean): void => {
    authStore.isValid = isValid;
  },
};
