import { Flex, Heading, Link, Text } from '@chakra-ui/react';
import { FullScreen } from '@components/Layout';
import MailIcon from '@icons/mail-01.svg?react';
import { useResendVerificationEmail } from '@src/auth/useRegister';
import { Alert } from '@ui/Alert';
import { FeaturedIcon } from '@ui/Icon';
import { FullScreenSpinner } from '@ui/Spinner';
import { FC } from 'react';

type CheckEmailProps = {
  email?: string;
  userId: string;
};

export const CheckEmail: FC<CheckEmailProps> = ({ email, userId }) => {
  const [resendVerification, { loading, error, called, reset }] =
    useResendVerificationEmail();

  const onResend = async () => {
    try {
      reset();
      await resendVerification(userId);
    } catch (err) {
      // Error shown in alert
      console.log(err);
    }
  };

  return (
    <FullScreen>
      {loading && <FullScreenSpinner />}
      <Flex
        flexDirection="column"
        alignItems="center"
        mt={{ base: 12, md: '96px' }}
        mb={{ base: '60px', md: '96px' }}
        mx={2}
      >
        <FeaturedIcon icon={MailIcon} colour="primary" />
        <Heading size="lg" mb={4} textAlign="center">
          Check your email
        </Heading>

        {email && (
          <>
            <Text textAlign="center">We sent a verification link to</Text>
            <Text textAlign="center" fontWeight="medium" mb={8}>
              {email}
            </Text>
          </>
        )}

        <Text textAlign="center" mb={8}>
          Didn’t receive the email?{' '}
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Link color="primary.700" fontWeight="600" onClick={onResend}>
            Click to resend
          </Link>
        </Text>

        {error && (
          <Alert
            containerProps={{ maxW: '400px' }}
            status="error"
            title="Error"
            description={error.message}
            onClose={reset}
          />
        )}

        {called && (
          <Alert
            containerProps={{ maxW: '400px' }}
            status="success"
            title="Email sent"
            description="We sent a verification link to your email address.&#10;Please click the link to verify your account."
            onClose={reset}
          />
        )}
      </Flex>
    </FullScreen>
  );
};
