import { FullScreen, WithSidebar } from '@components/Layout';
import VerifyEmail from '@routes/VerifyEmail';
import { FullScreenSpinner } from '@ui/Spinner';
import { FC, lazy, Suspense } from 'react';
import {
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useRoutes,
} from 'react-router-dom';

import { useAuth } from './auth';

const Home = lazy(() => import('@routes/Home'));
const PropertyHome = lazy(() => import('@routes/PropertyHome'));
const Property = lazy(() => import('@routes/Property'));
const CreateProperty = lazy(() => import('@routes/CreateProperty'));
const PropertySettings = lazy(() => import('@routes/PropertySettings'));
const PropertyFunnels = lazy(() => import('@routes/PropertyFunnels'));
const PropertyFunnel = lazy(() => import('@routes/PropertyFunnel'));
const ErrorPage = lazy(() => import('@routes/ErrorPage'));
const Login = lazy(() => import('@routes/Login'));
const SignUp = lazy(() => import('@routes/SignUp'));
const ForgotPassword = lazy(() => import('@routes/ForgotPassword'));
const ResetPassword = lazy(() => import('@routes/ResetPassword'));
const Invitation = lazy(() => import('@routes/Invitation'));
const NewVerification = lazy(() => import('@routes/NewVerification'));
const UserSettings = lazy(() => import('@routes/UserSettings'));
const TeamSettings = lazy(() => import('@routes/TeamSettings'));
const NotFound = lazy(() => import('@routes/NotFound'));
import { Flex, Spinner } from '@chakra-ui/react';
import { useProperties } from '@src/hooks/useProperties';

import { useUserProfile } from './hooks/useUserProfile';

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const auth = useAuth();
  const propertiesStatus = useProperties();
  const userProfileStatus = useUserProfile();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const location = useLocation();

  if (auth.isValid === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    auth.isValid === null ||
    propertiesStatus.loading ||
    userProfileStatus.loading
  ) {
    // We don't know if they're logged in or not yet, so we'll just show a
    // loading spinner.
    return (
      <FullScreen>
        <FullScreenSpinner />
      </FullScreen>
    );
  }

  return <WithSidebar>{children}</WithSidebar>;
};

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmail />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/new-verification',
    element: <NewVerification />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/invitation',
    element: <Invitation />,
    errorElement: <ErrorPage />,
  },
  {
    element: (
      <RequireAuth>
        <Suspense
          fallback={
            <Flex h="100vh" justifyContent="center" alignItems="center">
              <Spinner size="xl" color="primary.500" />
            </Flex>
          }
        >
          <Outlet />
        </Suspense>
      </RequireAuth>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/property/create',
        element: <CreateProperty />,
      },
      {
        path: '/property',
        element: <PropertyHome />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/property/:propertyId',
        element: <Property />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/property/:propertyId/settings',
        element: <PropertySettings />,
        errorElement: <ErrorPage />,
      },
      {
        // TODO: will this work with setCurrentProperty?
        path: '/funnels/:propertyId',
        element: <PropertyFunnels />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/funnels/:propertyId/funnel/:funnelId',
        element: <PropertyFunnel />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/user/settings',
        element: <UserSettings />,
      },
      {
        path: '/team/settings',
        element: <TeamSettings />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export const Routes: FC = () => {
  const routeElement = useRoutes(routes);
  return routeElement;
};
