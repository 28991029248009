/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends Record<string, unknown>,
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  DateTimeOffset: { input: string; output: string };
  Decimal: { input: number; output: number };
  Guid: { input: string; output: string };
  Long: { input: number; output: number };
  Upload: { input: unknown; output: unknown };
};

export type AcceptInvitationResultModel = {
  __typename: 'AcceptInvitationResultModel';
  token?: Maybe<Scalars['String']['output']>;
};

export type AvailablePlanModel = {
  __typename: 'AvailablePlanModel';
  currency: Scalars['String']['output'];
  entitlementAmount: Scalars['Int']['output'];
  entitlementType: EntitlementType;
  interval: Scalars['String']['output'];
  purchaseUrl: Scalars['String']['output'];
  unitAmount: Scalars['Long']['output'];
};

export type ConversionCompletionModel = {
  __typename: 'ConversionCompletionModel';
  completionCount: Scalars['Int']['output'];
  completionRate: Scalars['Decimal']['output'];
  id: Scalars['Guid']['output'];
  name: Scalars['String']['output'];
  propertyId: Scalars['Guid']['output'];
};

export type ConversionModel = {
  __typename: 'ConversionModel';
  createdOn: Scalars['DateTimeOffset']['output'];
  eventType: EventType;
  id: Scalars['Guid']['output'];
  name: Scalars['String']['output'];
  operator: ConversionOperator;
  path: Scalars['String']['output'];
  propertyId: Scalars['Guid']['output'];
};

export enum ConversionOperator {
  Contains = 'CONTAINS',
  Endswith = 'ENDSWITH',
  Equals = 'EQUALS',
  Startswith = 'STARTSWITH',
  Unknown = 'UNKNOWN',
}

export type DecimalPoint = {
  __typename: 'DecimalPoint';
  dateTime: Scalars['DateTimeOffset']['output'];
  value: Scalars['Decimal']['output'];
};

export enum EntitlementType {
  Freeeventallocation = 'FREEEVENTALLOCATION',
  Monthlyeventallocation = 'MONTHLYEVENTALLOCATION',
}

export enum EventType {
  Pageview = 'PAGEVIEW',
  Unknown = 'UNKNOWN',
}

export type FunnelConversionsModel = {
  __typename: 'FunnelConversionsModel';
  completedCount: Scalars['Int']['output'];
  conversionRate: Scalars['Decimal']['output'];
  description: Scalars['String']['output'];
  enteredCount: Scalars['Int']['output'];
  id: Scalars['Guid']['output'];
  name: Scalars['String']['output'];
  steps: FunnelConversionsStepModel[];
};

export type FunnelConversionsStepModel = {
  __typename: 'FunnelConversionsStepModel';
  conversionRate: Scalars['Decimal']['output'];
  conversions: Scalars['Int']['output'];
  eventType: EventType;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  stepNumber: Scalars['Int']['output'];
};

export type FunnelModel = {
  __typename: 'FunnelModel';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['DateTimeOffset']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Guid']['output'];
  name: Scalars['String']['output'];
  propertyId: Scalars['Guid']['output'];
  steps: FunnelStepModel[];
};

export type FunnelStepModel = {
  __typename: 'FunnelStepModel';
  eventType: EventType;
  name: Scalars['String']['output'];
  operator: ConversionOperator;
  path: Scalars['String']['output'];
};

export type Input_AcceptInvitationModel = {
  code: Scalars['String']['input'];
  subscriptionId: Scalars['Guid']['input'];
};

export type Input_BrowserFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_ChangePasswordModel = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type Input_ChannelFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_CityFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_ConversionModel = {
  createdOn?: Scalars['DateTimeOffset']['input'];
  eventType: EventType;
  id: Scalars['Guid']['input'];
  name: Scalars['String']['input'];
  operator: ConversionOperator;
  path: Scalars['String']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Input_CountryFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_CreateAccountModel = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  invitationSubscriptionId?: InputMaybe<Scalars['Guid']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Input_CreateConversionModel = {
  eventType: EventType;
  name: Scalars['String']['input'];
  operator: ConversionOperator;
  path: Scalars['String']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Input_CreateFunnelModel = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  propertyId: Scalars['Guid']['input'];
  steps: Input_FunnelStepModel[];
};

export type Input_CreatePropertyModel = {
  host: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subscriptionId: Scalars['Guid']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Input_DateRangeFilters = {
  end?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  period?: Period;
  start?: InputMaybe<Scalars['DateTimeOffset']['input']>;
};

export type Input_DateRangeWithIntervalFilters = {
  end?: InputMaybe<Scalars['DateTimeOffset']['input']>;
  interval?: Interval;
  period?: Period;
  start?: InputMaybe<Scalars['DateTimeOffset']['input']>;
};

export type Input_DeleteConversionModel = {
  id: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Input_DeleteFunnelModel = {
  id: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Input_DeletePropertyModel = {
  id: Scalars['Guid']['input'];
  subscriptionId: Scalars['Guid']['input'];
};

export type Input_DeviceFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_EntryPageFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_ExitPageFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_ForgotPasswordModel = {
  email: Scalars['String']['input'];
};

export type Input_FunnelModel = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdOn?: Scalars['DateTimeOffset']['input'];
  description: Scalars['String']['input'];
  id: Scalars['Guid']['input'];
  name: Scalars['String']['input'];
  propertyId: Scalars['Guid']['input'];
  steps?: Input_FunnelStepModel[];
};

export type Input_FunnelStepModel = {
  eventType: EventType;
  name: Scalars['String']['input'];
  operator: ConversionOperator;
  path: Scalars['String']['input'];
};

export type Input_GetSubscriptionMembersModel = {
  profileImageSize: Scalars['Int']['input'];
  subscriptionId: Scalars['Guid']['input'];
};

export type Input_GetSubscriptionPlanModel = {
  subscriptionId: Scalars['Guid']['input'];
};

export type Input_GetSubscriptionUsageModel = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  subscriptionId: Scalars['Guid']['input'];
};

export type Input_InvitationModel = {
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type Input_InviteUsersModel = {
  invitations: Input_InvitationModel[];
  subscriptionId: Scalars['Guid']['input'];
};

export type Input_LoginModel = {
  email: Scalars['String']['input'];
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  invitationSubscriptionId?: InputMaybe<Scalars['Guid']['input']>;
  password: Scalars['String']['input'];
  rememberMe?: Scalars['Boolean']['input'];
};

export type Input_NewEmailVerificationModel = {
  userId: Scalars['Guid']['input'];
};

export type Input_OperatingSystemFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_PageFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_PropertyModel = {
  additionalHosts?: InputMaybe<Scalars['String']['input'][]>;
  defaultApiToken?: InputMaybe<Scalars['String']['input']>;
  firstSentDataOn?: InputMaybe<Scalars['DateTime']['input']>;
  host: Scalars['String']['input'];
  id: Scalars['Guid']['input'];
  name: Scalars['String']['input'];
  subscriptionId: Scalars['Guid']['input'];
  timezone: Scalars['String']['input'];
};

export type Input_QueryFilters = {
  browser?: InputMaybe<Input_BrowserFilter>;
  channel?: InputMaybe<Input_ChannelFilter>;
  city?: InputMaybe<Input_CityFilter>;
  country?: InputMaybe<Input_CountryFilter>;
  device?: InputMaybe<Input_DeviceFilter>;
  entryPage?: InputMaybe<Input_EntryPageFilter>;
  exitPage?: InputMaybe<Input_ExitPageFilter>;
  operatingSystem?: InputMaybe<Input_OperatingSystemFilter>;
  page?: InputMaybe<Input_PageFilter>;
  referrer?: InputMaybe<Input_ReferrerFilter>;
  referrerSource?: InputMaybe<Input_ReferrerSourceFilter>;
  region?: InputMaybe<Input_RegionFilter>;
  utmCampaign?: InputMaybe<Input_UtmCampaignFilter>;
  utmContent?: InputMaybe<Input_UtmContentFilter>;
  utmMedium?: InputMaybe<Input_UtmMediumFilter>;
  utmSource?: InputMaybe<Input_UtmSourceFilter>;
  utmTerm?: InputMaybe<Input_UtmTermFilter>;
};

export type Input_ReferrerFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_ReferrerSourceFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_RefreshTokenModel = {
  jwtToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type Input_RegionFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_RemoveMemberModel = {
  subscriptionId: Scalars['Guid']['input'];
  userId: Scalars['Guid']['input'];
};

export type Input_ResetPasswordModel = {
  code: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  userId: Scalars['Guid']['input'];
};

export type Input_UpdateMemberModel = {
  role: Scalars['String']['input'];
  subscriptionId: Scalars['Guid']['input'];
  userId: Scalars['Guid']['input'];
};

export type Input_UpdateSubscriptionModel = {
  id: Scalars['Guid']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Input_UpdateUserProfileModel = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profileUrl?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Input_UtmCampaignFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_UtmContentFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_UtmMediumFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_UtmSourceFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_UtmTermFilter = {
  operator?: SearchableFilterOperator;
  values?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>;
};

export type Input_VerifyEmailModel = {
  code: Scalars['String']['input'];
  userId: Scalars['Guid']['input'];
};

export type IntegerPoint = {
  __typename: 'IntegerPoint';
  dateTime: Scalars['DateTimeOffset']['output'];
  value: Scalars['Int']['output'];
};

export enum Interval {
  Auto = 'AUTO',
  Oneday = 'ONEDAY',
  Onehour = 'ONEHOUR',
  Oneminute = 'ONEMINUTE',
  Onemonth = 'ONEMONTH',
  Oneweek = 'ONEWEEK',
  Thirtyminute = 'THIRTYMINUTE',
}

export type ListEntry = {
  __typename: 'ListEntry';
  count: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type LoginResult = {
  __typename: 'LoginResult';
  token?: Maybe<Scalars['String']['output']>;
};

export type LoginResultModel = {
  __typename: 'LoginResultModel';
  refreshToken?: Maybe<Scalars['String']['output']>;
  refreshTokenExpiry?: Maybe<Scalars['DateTime']['output']>;
  successfullyAddedToTeam: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type LookupValue = {
  __typename: 'LookupValue';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename: 'Mutation';
  account?: Maybe<Mutation_Account>;
  conversions?: Maybe<Mutation_Conversions>;
  funnels?: Maybe<Mutation_Funnels>;
  images?: Maybe<Mutation_Images>;
  profile?: Maybe<Mutation_Profile>;
  properties?: Maybe<Mutation_Properties>;
  subscriptions?: Maybe<Mutation_Subscriptions>;
};

export type Mutation_Account = {
  __typename: 'Mutation_Account';
  forgotPassword: Scalars['Boolean']['output'];
  login: LoginResultModel;
  logout: Scalars['Boolean']['output'];
  newEmailVerification: Scalars['Boolean']['output'];
  refreshToken: LoginResultModel;
  register: Scalars['Guid']['output'];
  resetPassword: LoginResultModel;
  verifyEmail: LoginResultModel;
};

export type Mutation_AccountForgotPasswordArgs = {
  model: Input_ForgotPasswordModel;
};

export type Mutation_AccountLoginArgs = {
  model: Input_LoginModel;
};

export type Mutation_AccountNewEmailVerificationArgs = {
  model: Input_NewEmailVerificationModel;
};

export type Mutation_AccountRefreshTokenArgs = {
  model: Input_RefreshTokenModel;
};

export type Mutation_AccountRegisterArgs = {
  model: Input_CreateAccountModel;
};

export type Mutation_AccountResetPasswordArgs = {
  model: Input_ResetPasswordModel;
};

export type Mutation_AccountVerifyEmailArgs = {
  model: Input_VerifyEmailModel;
};

export type Mutation_Conversions = {
  __typename: 'Mutation_Conversions';
  create: ConversionModel;
  delete: Scalars['Boolean']['output'];
  update: ConversionModel;
};

export type Mutation_ConversionsCreateArgs = {
  model: Input_CreateConversionModel;
};

export type Mutation_ConversionsDeleteArgs = {
  model: Input_DeleteConversionModel;
};

export type Mutation_ConversionsUpdateArgs = {
  model: Input_ConversionModel;
};

export type Mutation_Funnels = {
  __typename: 'Mutation_Funnels';
  create: FunnelModel;
  delete: Scalars['Boolean']['output'];
  update: FunnelModel;
};

export type Mutation_FunnelsCreateArgs = {
  model: Input_CreateFunnelModel;
};

export type Mutation_FunnelsDeleteArgs = {
  model: Input_DeleteFunnelModel;
};

export type Mutation_FunnelsUpdateArgs = {
  model: Input_FunnelModel;
};

export type Mutation_Images = {
  __typename: 'Mutation_Images';
  uploadProfileImage?: Maybe<Scalars['String']['output']>;
};

export type Mutation_ImagesUploadProfileImageArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};

export type Mutation_Profile = {
  __typename: 'Mutation_Profile';
  changePassword: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

export type Mutation_ProfileChangePasswordArgs = {
  model: Input_ChangePasswordModel;
};

export type Mutation_ProfileUpdateArgs = {
  model: Input_UpdateUserProfileModel;
};

export type Mutation_Properties = {
  __typename: 'Mutation_Properties';
  create: PropertyModel;
  delete: Scalars['Boolean']['output'];
  update: PropertyModel;
};

export type Mutation_PropertiesCreateArgs = {
  model: Input_CreatePropertyModel;
};

export type Mutation_PropertiesDeleteArgs = {
  model: Input_DeletePropertyModel;
};

export type Mutation_PropertiesUpdateArgs = {
  model: Input_PropertyModel;
};

export type Mutation_Subscriptions = {
  __typename: 'Mutation_Subscriptions';
  acceptInvitation?: Maybe<AcceptInvitationResultModel>;
  inviteUsers: Scalars['Boolean']['output'];
  removeMember: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updateMember: Scalars['Boolean']['output'];
};

export type Mutation_SubscriptionsAcceptInvitationArgs = {
  model: Input_AcceptInvitationModel;
};

export type Mutation_SubscriptionsInviteUsersArgs = {
  model: Input_InviteUsersModel;
};

export type Mutation_SubscriptionsRemoveMemberArgs = {
  model: Input_RemoveMemberModel;
};

export type Mutation_SubscriptionsUpdateArgs = {
  model: Input_UpdateSubscriptionModel;
};

export type Mutation_SubscriptionsUpdateMemberArgs = {
  model: Input_UpdateMemberModel;
};

export enum Period {
  Custom = 'CUSTOM',
  Lastmonth = 'LASTMONTH',
  Lasttwelvemonths = 'LASTTWELVEMONTHS',
  Oneday = 'ONEDAY',
  Sevendays = 'SEVENDAYS',
  Thirtydays = 'THIRTYDAYS',
}

export enum PlanType {
  Free = 'FREE',
  Growth = 'GROWTH',
  Starter = 'STARTER',
}

export type PropertyModel = {
  __typename: 'PropertyModel';
  additionalHosts?: Maybe<Scalars['String']['output'][]>;
  defaultApiToken?: Maybe<Scalars['String']['output']>;
  firstSentDataOn?: Maybe<Scalars['DateTime']['output']>;
  host: Scalars['String']['output'];
  id: Scalars['Guid']['output'];
  name: Scalars['String']['output'];
  subscriptionId: Scalars['Guid']['output'];
  timezone: Scalars['String']['output'];
};

export type PropertySummaryModel = {
  __typename: 'PropertySummaryModel';
  firstSentDataOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Guid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['Guid']['output'];
};

export type PropertyUsageModel = {
  __typename: 'PropertyUsageModel';
  id: Scalars['Guid']['output'];
  name: Scalars['String']['output'];
  usage: Scalars['Int']['output'];
};

export type Query = {
  __typename: 'Query';
  auth?: Maybe<Query_Auth>;
  conversions?: Maybe<Query_Conversions>;
  funnels?: Maybe<Query_Funnels>;
  lists?: Maybe<Query_Lists>;
  lookups?: Maybe<Query_Lookups>;
  metrics?: Maybe<Query_Metrics>;
  profile?: Maybe<Query_Profile>;
  properties?: Maybe<Query_Properties>;
  series?: Maybe<Query_Series>;
  subscriptions?: Maybe<Query_Subscriptions>;
};

export type Query_Auth = {
  __typename: 'Query_Auth';
  /** @deprecated Use the new login method that takes a username and password */
  login: LoginResult;
};

export type Query_AuthLoginArgs = {
  accessToken: Scalars['String']['input'];
};

export type Query_Conversions = {
  __typename: 'Query_Conversions';
  completions: ConversionCompletionModel[];
  get: ConversionModel;
};

export type Query_ConversionsCompletionsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Query_ConversionsGetArgs = {
  conversionId: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Query_Funnels = {
  __typename: 'Query_Funnels';
  get: FunnelModel;
  getAll: FunnelModel[];
  getConversions: FunnelConversionsModel;
};

export type Query_FunnelsGetArgs = {
  id: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Query_FunnelsGetAllArgs = {
  propertyId: Scalars['Guid']['input'];
};

export type Query_FunnelsGetConversionsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  id: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
};

export type Query_Lists = {
  __typename: 'Query_Lists';
  browsers: ListEntry[];
  channels: ListEntry[];
  cities: ListEntry[];
  countries: ListEntry[];
  devices: ListEntry[];
  entryPages: ListEntry[];
  exitPages: ListEntry[];
  operatingSystems: ListEntry[];
  pageViews: ListEntry[];
  referrers: ListEntry[];
  regions: ListEntry[];
  sources: ListEntry[];
  utmcampaigns: ListEntry[];
  utmcontents: ListEntry[];
  utmmediums: ListEntry[];
  utmsources: ListEntry[];
};

export type Query_ListsBrowsersArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsChannelsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsCitiesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsCountriesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsDevicesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsEntryPagesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsExitPagesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsOperatingSystemsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsPageViewsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsReferrersArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsRegionsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsSourcesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsUtmcampaignsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsUtmcontentsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsUtmmediumsArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_ListsUtmsourcesArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_Lookups = {
  __typename: 'Query_Lookups';
  browserVersions?: Maybe<Maybe<Scalars['String']['output']>[]>;
  browsers?: Maybe<Maybe<Scalars['String']['output']>[]>;
  channels?: Maybe<Maybe<Scalars['String']['output']>[]>;
  cities?: Maybe<Maybe<Scalars['String']['output']>[]>;
  countries?: Maybe<Maybe<LookupValue>[]>;
  deviceModels?: Maybe<Maybe<Scalars['String']['output']>[]>;
  devices?: Maybe<Maybe<Scalars['String']['output']>[]>;
  entrypages?: Maybe<Maybe<Scalars['String']['output']>[]>;
  exitpages?: Maybe<Maybe<Scalars['String']['output']>[]>;
  operatingSystemVersions?: Maybe<Maybe<Scalars['String']['output']>[]>;
  operatingSystems?: Maybe<Maybe<Scalars['String']['output']>[]>;
  pages?: Maybe<Maybe<Scalars['String']['output']>[]>;
  referrers?: Maybe<Maybe<Scalars['String']['output']>[]>;
  regions?: Maybe<Maybe<Scalars['String']['output']>[]>;
  screenSizes?: Maybe<Maybe<Scalars['String']['output']>[]>;
  sources?: Maybe<Maybe<Scalars['String']['output']>[]>;
  timeZones?: Maybe<Maybe<LookupValue>[]>;
  utmCampaigns?: Maybe<Maybe<Scalars['String']['output']>[]>;
  utmContent?: Maybe<Maybe<Scalars['String']['output']>[]>;
  utmMediums?: Maybe<Maybe<Scalars['String']['output']>[]>;
  utmSources?: Maybe<Maybe<Scalars['String']['output']>[]>;
  utmTerms?: Maybe<Maybe<Scalars['String']['output']>[]>;
};

export type Query_LookupsBrowserVersionsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsBrowsersArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsChannelsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsCitiesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsDeviceModelsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsDevicesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsEntrypagesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsExitpagesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsOperatingSystemVersionsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsOperatingSystemsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsPagesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsReferrersArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsRegionsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsScreenSizesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsSourcesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsUtmCampaignsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsUtmContentArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsUtmMediumsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsUtmSourcesArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_LookupsUtmTermsArgs = {
  date?: InputMaybe<Input_DateRangeFilters>;
  propertyId: Scalars['Guid']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};

export type Query_Metrics = {
  __typename: 'Query_Metrics';
  all: TopLevelMetricsResult;
};

export type Query_MetricsAllArgs = {
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
};

export type Query_Profile = {
  __typename: 'Query_Profile';
  get: UserProfileModel;
};

export type Query_ProfileGetArgs = {
  size?: Scalars['Int']['input'];
};

export type Query_Properties = {
  __typename: 'Query_Properties';
  get: PropertyModel;
  getAll: PropertySummaryModel[];
};

export type Query_PropertiesGetArgs = {
  propertyId: Scalars['Guid']['input'];
};

export type Query_Series = {
  __typename: 'Query_Series';
  bounceRate: TimeSeriesResult_DecimalPoint_;
  sessionCount: TimeSeriesResult_IntegerPoint_;
  sessionDuration: TimeSeriesResult_IntegerPoint_;
  visitorCount: TimeSeriesResult_IntegerPoint_;
};

export type Query_SeriesBounceRateArgs = {
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
};

export type Query_SeriesSessionCountArgs = {
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
};

export type Query_SeriesSessionDurationArgs = {
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
};

export type Query_SeriesVisitorCountArgs = {
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
  propertyId: Scalars['Guid']['input'];
};

export type Query_Subscriptions = {
  __typename: 'Query_Subscriptions';
  getAll: SubscriptionModel[];
  listMembers: SubscriptionMemberModel[];
  plan: SubscriptionPlanModel;
  usage: SubscriptionUsageModel;
};

export type Query_SubscriptionsListMembersArgs = {
  model: Input_GetSubscriptionMembersModel;
};

export type Query_SubscriptionsPlanArgs = {
  model: Input_GetSubscriptionPlanModel;
};

export type Query_SubscriptionsUsageArgs = {
  model: Input_GetSubscriptionUsageModel;
};

export enum SearchableFilterOperator {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  Notequals = 'NOTEQUALS',
}

export type SubscriptionMemberModel = {
  __typename: 'SubscriptionMemberModel';
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  isCurrentUser: Scalars['Boolean']['output'];
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Guid']['output'];
};

export type SubscriptionModel = {
  __typename: 'SubscriptionModel';
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['Guid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  planType: PlanType;
  role?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanModel = {
  __typename: 'SubscriptionPlanModel';
  availablePlans?: Maybe<Maybe<AvailablePlanModel>[]>;
  currency: Scalars['String']['output'];
  entitlementAmount: Scalars['Int']['output'];
  entitlementType: EntitlementType;
  id: Scalars['Guid']['output'];
  interval: Scalars['String']['output'];
  managePlanUrl?: Maybe<Scalars['String']['output']>;
  unitAmount: Scalars['Long']['output'];
  upgradePlanUrl?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionUsageModel = {
  __typename: 'SubscriptionUsageModel';
  id: Scalars['Guid']['output'];
  propertyUsage?: Maybe<Maybe<PropertyUsageModel>[]>;
  totalUsage: Scalars['Int']['output'];
};

export type TimeSeriesResult_DecimalPoint_ = {
  __typename: 'TimeSeriesResult_DecimalPoint_';
  data?: Maybe<Maybe<DecimalPoint>[]>;
  interval: Interval;
};

export type TimeSeriesResult_IntegerPoint_ = {
  __typename: 'TimeSeriesResult_IntegerPoint_';
  data?: Maybe<Maybe<IntegerPoint>[]>;
  interval: Interval;
};

export type TopLevelMetricsResult = {
  __typename: 'TopLevelMetricsResult';
  bounceRate: Scalars['Decimal']['output'];
  bounceRateChange: Scalars['Decimal']['output'];
  bounceRatePrevious: Scalars['Decimal']['output'];
  sessionDuration: Scalars['Int']['output'];
  sessionDurationChange: Scalars['Decimal']['output'];
  sessionDurationPrevious: Scalars['Int']['output'];
  sessions: Scalars['Int']['output'];
  sessionsChange: Scalars['Decimal']['output'];
  sessionsPrevious: Scalars['Int']['output'];
  visitors: Scalars['Int']['output'];
  visitorsChange: Scalars['Decimal']['output'];
  visitorsPrevious: Scalars['Int']['output'];
};

export type UserProfileModel = {
  __typename: 'UserProfileModel';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Guid']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  subscriptions?: Maybe<Maybe<SubscriptionModel>[]>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type RefreshTokenMutationVariables = Exact<{
  model: Input_RefreshTokenModel;
}>;

export type RefreshTokenMutation = {
  __typename: 'Mutation';
  account?: {
    __typename: 'Mutation_Account';
    refreshToken: {
      __typename: 'LoginResultModel';
      token?: string | null;
      refreshToken?: string | null;
      refreshTokenExpiry?: string | null;
    };
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  model: Input_ForgotPasswordModel;
}>;

export type ForgotPasswordMutation = {
  __typename: 'Mutation';
  account?: { __typename: 'Mutation_Account'; forgotPassword: boolean } | null;
};

export type LoginMutationVariables = Exact<{
  model: Input_LoginModel;
}>;

export type LoginMutation = {
  __typename: 'Mutation';
  account?: {
    __typename: 'Mutation_Account';
    login: {
      __typename: 'LoginResultModel';
      refreshToken?: string | null;
      refreshTokenExpiry?: string | null;
      token?: string | null;
    };
  } | null;
};

export type LogoutMutationVariables = Exact<Record<string, never>>;

export type LogoutMutation = {
  __typename: 'Mutation';
  account?: { __typename: 'Mutation_Account'; logout: boolean } | null;
};

export type RegisterMutationVariables = Exact<{
  model: Input_CreateAccountModel;
}>;

export type RegisterMutation = {
  __typename: 'Mutation';
  account?: { __typename: 'Mutation_Account'; register: string } | null;
};

export type ResendVerificationEmailMutationVariables = Exact<{
  userId: Scalars['Guid']['input'];
}>;

export type ResendVerificationEmailMutation = {
  __typename: 'Mutation';
  account?: {
    __typename: 'Mutation_Account';
    newEmailVerification: boolean;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  model: Input_ResetPasswordModel;
}>;

export type ResetPasswordMutation = {
  __typename: 'Mutation';
  account?: {
    __typename: 'Mutation_Account';
    resetPassword: {
      __typename: 'LoginResultModel';
      refreshToken?: string | null;
      refreshTokenExpiry?: string | null;
      token?: string | null;
    };
  } | null;
};

export type VerifyEmailMutationVariables = Exact<{
  model: Input_VerifyEmailModel;
}>;

export type VerifyEmailMutation = {
  __typename: 'Mutation';
  account?: {
    __typename: 'Mutation_Account';
    verifyEmail: {
      __typename: 'LoginResultModel';
      refreshToken?: string | null;
      refreshTokenExpiry?: string | null;
      token?: string | null;
    };
  } | null;
};

export type BounceRateChartQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type BounceRateChartQuery = {
  __typename: 'Query';
  series?: {
    __typename: 'Query_Series';
    bounceRate: {
      __typename: 'TimeSeriesResult_DecimalPoint_';
      interval: Interval;
      data?: ({
        __typename: 'DecimalPoint';
        dateTime: string;
        value: number;
      } | null)[] | null;
    };
  } | null;
};

export type MetricsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type MetricsQuery = {
  __typename: 'Query';
  metrics?: {
    __typename: 'Query_Metrics';
    all: {
      __typename: 'TopLevelMetricsResult';
      visitors: number;
      visitorsPrevious: number;
      visitorsChange: number;
      sessions: number;
      sessionsPrevious: number;
      sessionsChange: number;
      bounceRate: number;
      bounceRatePrevious: number;
      bounceRateChange: number;
      sessionDuration: number;
      sessionDurationPrevious: number;
      sessionDurationChange: number;
    };
  } | null;
};

export type SessionDurationChartQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type SessionDurationChartQuery = {
  __typename: 'Query';
  series?: {
    __typename: 'Query_Series';
    sessionDuration: {
      __typename: 'TimeSeriesResult_IntegerPoint_';
      interval: Interval;
      data?: ({
        __typename: 'IntegerPoint';
        dateTime: string;
        value: number;
      } | null)[] | null;
    };
  } | null;
};

export type SessionsChartQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type SessionsChartQuery = {
  __typename: 'Query';
  series?: {
    __typename: 'Query_Series';
    sessionCount: {
      __typename: 'TimeSeriesResult_IntegerPoint_';
      interval: Interval;
      data?: ({
        __typename: 'IntegerPoint';
        dateTime: string;
        value: number;
      } | null)[] | null;
    };
  } | null;
};

export type VisitorsChartQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeWithIntervalFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type VisitorsChartQuery = {
  __typename: 'Query';
  series?: {
    __typename: 'Query_Series';
    visitorCount: {
      __typename: 'TimeSeriesResult_IntegerPoint_';
      interval: Interval;
      data?: ({
        __typename: 'IntegerPoint';
        dateTime: string;
        value: number;
      } | null)[] | null;
    };
  } | null;
};

export type CountriesLookupQueryVariables = Exact<Record<string, never>>;

export type CountriesLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    countries?: ({
      __typename: 'LookupValue';
      code?: string | null;
      name?: string | null;
    } | null)[] | null;
  } | null;
};

export type PagesLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type PagesLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    pages?: (string | null)[] | null;
  } | null;
};

export type ReferrersLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type ReferrersLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    referrers?: (string | null)[] | null;
  } | null;
};

export type SourcesLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type SourcesLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    sources?: (string | null)[] | null;
  } | null;
};

export type ScreenSizesLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type ScreenSizesLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    screenSizes?: (string | null)[] | null;
  } | null;
};

export type OperatingSystemsLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type OperatingSystemsLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    operatingSystems?: (string | null)[] | null;
  } | null;
};

export type BrowsersLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type BrowsersLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    browsers?: (string | null)[] | null;
  } | null;
};

export type BrowserVersionsLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type BrowserVersionsLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    browserVersions?: (string | null)[] | null;
  } | null;
};

export type UtmMediumsLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type UtmMediumsLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    utmMediums?: (string | null)[] | null;
  } | null;
};

export type UtmSourcesLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type UtmSourcesLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    utmSources?: (string | null)[] | null;
  } | null;
};

export type UtmCampaignsLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type UtmCampaignsLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    utmCampaigns?: (string | null)[] | null;
  } | null;
};

export type UtmContentLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type UtmContentLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    utmContent?: (string | null)[] | null;
  } | null;
};

export type UtmTermsLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type UtmTermsLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    utmTerms?: (string | null)[] | null;
  } | null;
};

export type DevicesLookupQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
}>;

export type DevicesLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    devices?: (string | null)[] | null;
  } | null;
};

export type ListEntryFieldsFragment = {
  __typename: 'ListEntry';
  url?: string | null;
  value: string;
  count: number;
};

export type UtMcampaignsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type UtMcampaignsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    utmcampaigns: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type UtMcontentsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type UtMcontentsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    utmcontents: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type UtMmediumsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type UtMmediumsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    utmmediums: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type UtMsourcesStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type UtMsourcesStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    utmsources: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type ConversionFieldsFragment = {
  __typename: 'ConversionCompletionModel';
  id: string;
  propertyId: string;
  name: string;
  completionCount: number;
  completionRate: number;
};

export type ConversionsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type ConversionsQuery = {
  __typename: 'Query';
  conversions?: {
    __typename: 'Query_Conversions';
    completions: ({ __typename: 'ConversionCompletionModel' } & ConversionFieldsFragment)[];
  } | null;
};

export type CitiesStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type CitiesStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    cities: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type CountriesStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type CountriesStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    countries: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type RegionsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type RegionsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    regions: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type ExitPagesStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type ExitPagesStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    exitPages: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type EntryPagesStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type EntryPagesStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    entryPages: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type PageViewsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type PageViewsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    pageViews: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type BrowsersStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type BrowsersStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    browsers: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type DevicesStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type DevicesStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    devices: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type OperatingSystemsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type OperatingSystemsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    operatingSystems: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type ChannelsStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type ChannelsStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    channels: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type ReferrersStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type ReferrersStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    referrers: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type TrafficStatsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type TrafficStatsQuery = {
  __typename: 'Query';
  lists?: {
    __typename: 'Query_Lists';
    sources: ({ __typename: 'ListEntry' } & ListEntryFieldsFragment)[];
  } | null;
};

export type AcceptInvitationMutationVariables = Exact<{
  model: Input_AcceptInvitationModel;
}>;

export type AcceptInvitationMutation = {
  __typename: 'Mutation';
  subscriptions?: {
    __typename: 'Mutation_Subscriptions';
    acceptInvitation?: {
      __typename: 'AcceptInvitationResultModel';
      token?: string | null;
    } | null;
  } | null;
};

export type ConversionFetchQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
  conversionId: Scalars['Guid']['input'];
}>;

export type ConversionFetchQuery = {
  __typename: 'Query';
  conversions?: {
    __typename: 'Query_Conversions';
    get: {
      __typename: 'ConversionModel';
      id: string;
      propertyId: string;
      name: string;
      path: string;
      eventType: EventType;
      operator: ConversionOperator;
      createdOn: string;
    };
  } | null;
};

export type CreateConversionMutationVariables = Exact<{
  model: Input_CreateConversionModel;
}>;

export type CreateConversionMutation = {
  __typename: 'Mutation';
  conversions?: {
    __typename: 'Mutation_Conversions';
    create: {
      __typename: 'ConversionModel';
      id: string;
      propertyId: string;
      name: string;
      path: string;
      eventType: EventType;
      operator: ConversionOperator;
      createdOn: string;
    };
  } | null;
};

export type DeleteConversionMutationVariables = Exact<{
  model: Input_DeleteConversionModel;
}>;

export type DeleteConversionMutation = {
  __typename: 'Mutation';
  conversions?: { __typename: 'Mutation_Conversions'; delete: boolean } | null;
};

export type UpdateConversionMutationVariables = Exact<{
  model: Input_ConversionModel;
}>;

export type UpdateConversionMutation = {
  __typename: 'Mutation';
  conversions?: {
    __typename: 'Mutation_Conversions';
    update: {
      __typename: 'ConversionModel';
      id: string;
      propertyId: string;
      name: string;
      path: string;
      eventType: EventType;
      operator: ConversionOperator;
      createdOn: string;
    };
  } | null;
};

export type UpdateFunnelMutationVariables = Exact<{
  model: Input_FunnelModel;
}>;

export type UpdateFunnelMutation = {
  __typename: 'Mutation';
  funnels?: {
    __typename: 'Mutation_Funnels';
    update: { __typename: 'FunnelModel'; id: string };
  } | null;
};

export type FunnelQueryVariables = Exact<{
  funnelId: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
}>;

export type FunnelQuery = {
  __typename: 'Query';
  funnels?: {
    __typename: 'Query_Funnels';
    get: { __typename: 'FunnelModel' } & FunnelFragment;
  } | null;
};

export type CreateFunnelMutationVariables = Exact<{
  model: Input_CreateFunnelModel;
}>;

export type CreateFunnelMutation = {
  __typename: 'Mutation';
  funnels?: {
    __typename: 'Mutation_Funnels';
    create: {
      __typename: 'FunnelModel';
      id: string;
      propertyId: string;
      name: string;
      description: string;
      createdBy?: string | null;
      createdOn: string;
      steps: {
        __typename: 'FunnelStepModel';
        name: string;
        path: string;
        eventType: EventType;
        operator: ConversionOperator;
      }[];
    };
  } | null;
};

export type DeleteFunnelMutationVariables = Exact<{
  model: Input_DeleteFunnelModel;
}>;

export type DeleteFunnelMutation = {
  __typename: 'Mutation';
  funnels?: { __typename: 'Mutation_Funnels'; delete: boolean } | null;
};

export type FunnelsQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
}>;

export type FunnelsQuery = {
  __typename: 'Query';
  funnels?: {
    __typename: 'Query_Funnels';
    getAll: ({ __typename: 'FunnelModel' } & FunnelFragment)[];
  } | null;
};

export type FunnelFragment = {
  __typename: 'FunnelModel';
  id: string;
  propertyId: string;
  name: string;
  description: string;
  createdBy?: string | null;
  createdOn: string;
  steps: {
    __typename: 'FunnelStepModel';
    name: string;
    path: string;
    eventType: EventType;
    operator: ConversionOperator;
  }[];
};

export type InviteTeamMembersMutationVariables = Exact<{
  model: Input_InviteUsersModel;
}>;

export type InviteTeamMembersMutation = {
  __typename: 'Mutation';
  subscriptions?: {
    __typename: 'Mutation_Subscriptions';
    inviteUsers: boolean;
  } | null;
};

export type UpdatePasswordMutationVariables = Exact<{
  model: Input_ChangePasswordModel;
}>;

export type UpdatePasswordMutation = {
  __typename: 'Mutation';
  profile?: { __typename: 'Mutation_Profile'; changePassword: boolean } | null;
};

export type PropertyListFragment = {
  __typename: 'PropertySummaryModel';
  id: string;
  name?: string | null;
};

export type AllPropertiesQueryVariables = Exact<Record<string, never>>;

export type AllPropertiesQuery = {
  __typename: 'Query';
  properties?: {
    __typename: 'Query_Properties';
    getAll: {
      __typename: 'PropertySummaryModel';
      id: string;
      name?: string | null;
    }[];
  } | null;
};

export type PropertyFetchQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
}>;

export type PropertyFetchQuery = {
  __typename: 'Query';
  properties?: {
    __typename: 'Query_Properties';
    get: {
      __typename: 'PropertyModel';
      id: string;
      subscriptionId: string;
      name: string;
      host: string;
      timezone: string;
      firstSentDataOn?: string | null;
      additionalHosts?: string[] | null;
      defaultApiToken?: string | null;
    };
  } | null;
};

export type PropertyFirstSentQueryVariables = Exact<{
  propertyId: Scalars['Guid']['input'];
}>;

export type PropertyFirstSentQuery = {
  __typename: 'Query';
  properties?: {
    __typename: 'Query_Properties';
    get: {
      __typename: 'PropertyModel';
      id: string;
      firstSentDataOn?: string | null;
    };
  } | null;
};

export type CreatePropertyMutationVariables = Exact<{
  model: Input_CreatePropertyModel;
}>;

export type CreatePropertyMutation = {
  __typename: 'Mutation';
  properties?: {
    __typename: 'Mutation_Properties';
    create: {
      __typename: 'PropertyModel';
      id: string;
      name: string;
      host: string;
      firstSentDataOn?: string | null;
      additionalHosts?: string[] | null;
      timezone: string;
    };
  } | null;
};

export type DeletePropertyMutationVariables = Exact<{
  model: Input_DeletePropertyModel;
}>;

export type DeletePropertyMutation = {
  __typename: 'Mutation';
  properties?: { __typename: 'Mutation_Properties'; delete: boolean } | null;
};

export type UpdatePropertyMutationVariables = Exact<{
  model: Input_PropertyModel;
}>;

export type UpdatePropertyMutation = {
  __typename: 'Mutation';
  properties?: {
    __typename: 'Mutation_Properties';
    update: {
      __typename: 'PropertyModel';
      id: string;
      name: string;
      host: string;
      firstSentDataOn?: string | null;
      additionalHosts?: string[] | null;
      timezone: string;
    };
  } | null;
};

export type SubscriptionPlanQueryVariables = Exact<{
  subscriptionId: Scalars['Guid']['input'];
}>;

export type SubscriptionPlanQuery = {
  __typename: 'Query';
  subscriptions?: {
    __typename: 'Query_Subscriptions';
    plan: {
      __typename: 'SubscriptionPlanModel';
      id: string;
      currency: string;
      unitAmount: number;
      interval: string;
      entitlementType: EntitlementType;
      entitlementAmount: number;
      managePlanUrl?: string | null;
      upgradePlanUrl?: string | null;
      availablePlans?: ({
        __typename: 'AvailablePlanModel';
        currency: string;
        unitAmount: number;
        interval: string;
        entitlementType: EntitlementType;
        entitlementAmount: number;
        purchaseUrl: string;
      } | null)[] | null;
    };
  } | null;
};

export type SubscriptionUpdateMutationVariables = Exact<{
  model: Input_UpdateSubscriptionModel;
}>;

export type SubscriptionUpdateMutation = {
  __typename: 'Mutation';
  subscriptions?: {
    __typename: 'Mutation_Subscriptions';
    update: boolean;
  } | null;
};

export type SubscriptionUsageQueryVariables = Exact<{
  model: Input_GetSubscriptionUsageModel;
}>;

export type SubscriptionUsageQuery = {
  __typename: 'Query';
  subscriptions?: {
    __typename: 'Query_Subscriptions';
    usage: {
      __typename: 'SubscriptionUsageModel';
      id: string;
      totalUsage: number;
      propertyUsage?: ({
        __typename: 'PropertyUsageModel';
        id: string;
        name: string;
        usage: number;
      } | null)[] | null;
    };
  } | null;
};

export type SubscriptionFragment = {
  __typename: 'SubscriptionModel';
  id: string;
  name?: string | null;
  planType: PlanType;
  createdOn: string;
};

export type SubscriptionsQueryVariables = Exact<Record<string, never>>;

export type SubscriptionsQuery = {
  __typename: 'Query';
  subscriptions?: {
    __typename: 'Query_Subscriptions';
    getAll: ({ __typename: 'SubscriptionModel' } & SubscriptionFragment)[];
  } | null;
};

export type TeamMemberRemoveMutationVariables = Exact<{
  model: Input_RemoveMemberModel;
}>;

export type TeamMemberRemoveMutation = {
  __typename: 'Mutation';
  subscriptions?: {
    __typename: 'Mutation_Subscriptions';
    removeMember: boolean;
  } | null;
};

export type TeamMemberUpdateMutationVariables = Exact<{
  model: Input_UpdateMemberModel;
}>;

export type TeamMemberUpdateMutation = {
  __typename: 'Mutation';
  subscriptions?: {
    __typename: 'Mutation_Subscriptions';
    updateMember: boolean;
  } | null;
};

export type TeamMemberFragment = {
  __typename: 'SubscriptionMemberModel';
  userId: string;
  fullName?: string | null;
  email?: string | null;
  profileImageUrl?: string | null;
  role?: string | null;
};

export type TeamMembersQueryVariables = Exact<{
  model: Input_GetSubscriptionMembersModel;
}>;

export type TeamMembersQuery = {
  __typename: 'Query';
  subscriptions?: {
    __typename: 'Query_Subscriptions';
    listMembers: ({ __typename: 'SubscriptionMemberModel' } & TeamMemberFragment)[];
  } | null;
};

export type UserProfileFieldsFragment = {
  __typename: 'UserProfileModel';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  timezone?: string | null;
  email?: string | null;
  profileUrl?: string | null;
  subscriptions?: ({
    __typename: 'SubscriptionModel';
    id: string;
    name?: string | null;
    role?: string | null;
    planType: PlanType;
    createdOn: string;
  } | null)[] | null;
};

export type UserProfileQueryVariables = Exact<Record<string, never>>;

export type UserProfileQuery = {
  __typename: 'Query';
  profile?: {
    __typename: 'Query_Profile';
    get: { __typename: 'UserProfileModel' } & UserProfileFieldsFragment;
  } | null;
};

export type UpdateUserProfileMutationVariables = Exact<{
  model: Input_UpdateUserProfileModel;
}>;

export type UpdateUserProfileMutation = {
  __typename: 'Mutation';
  profile?: { __typename: 'Mutation_Profile'; update: boolean } | null;
};

export type FunnelConversionQueryVariables = Exact<{
  funnelId: Scalars['Guid']['input'];
  propertyId: Scalars['Guid']['input'];
  date: Input_DateRangeFilters;
  filters?: InputMaybe<Input_QueryFilters>;
}>;

export type FunnelConversionQuery = {
  __typename: 'Query';
  funnels?: {
    __typename: 'Query_Funnels';
    getConversions: {
      __typename: 'FunnelConversionsModel';
      id: string;
      name: string;
      description: string;
      enteredCount: number;
      completedCount: number;
      conversionRate: number;
      steps: {
        __typename: 'FunnelConversionsStepModel';
        name: string;
        path: string;
        eventType: EventType;
        conversions: number;
        conversionRate: number;
      }[];
    };
  } | null;
};

export type TimezoneLookupQueryVariables = Exact<Record<string, never>>;

export type TimezoneLookupQuery = {
  __typename: 'Query';
  lookups?: {
    __typename: 'Query_Lookups';
    timeZones?: ({
      __typename: 'LookupValue';
      code?: string | null;
      name?: string | null;
    } | null)[] | null;
  } | null;
};

export const ListEntryFieldsFragmentDoc = gql`
  fragment ListEntryFields on ListEntry {
    url
    value
    count
  }
`;
export const ConversionFieldsFragmentDoc = gql`
  fragment ConversionFields on ConversionCompletionModel {
    id
    propertyId
    name
    completionCount
    completionRate
  }
`;
export const FunnelFragmentDoc = gql`
  fragment Funnel on FunnelModel {
    id
    propertyId
    name
    description
    createdBy
    createdOn
    steps {
      name
      path
      eventType
      operator
    }
  }
`;
export const PropertyListFragmentDoc = gql`
  fragment PropertyList on PropertySummaryModel {
    id
    name
  }
`;
export const SubscriptionFragmentDoc = gql`
  fragment Subscription on SubscriptionModel {
    id
    name
    planType
    createdOn
  }
`;
export const TeamMemberFragmentDoc = gql`
  fragment TeamMember on SubscriptionMemberModel {
    userId
    fullName
    email
    profileImageUrl
    role
  }
`;
export const UserProfileFieldsFragmentDoc = gql`
  fragment UserProfileFields on UserProfileModel {
    id
    firstName
    lastName
    timezone
    email
    profileUrl
    subscriptions {
      id
      name
      role
      planType
      createdOn
    }
  }
`;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($model: Input_RefreshTokenModel!) {
    account {
      refreshToken(model: $model) {
        token
        refreshToken
        refreshTokenExpiry
      }
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($model: Input_ForgotPasswordModel!) {
    account {
      forgotPassword(model: $model)
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($model: Input_LoginModel!) {
    account {
      login(model: $model) {
        refreshToken
        refreshTokenExpiry
        token
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    account {
      logout
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register($model: Input_CreateAccountModel!) {
    account {
      register(model: $model)
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const ResendVerificationEmailDocument = gql`
  mutation ResendVerificationEmail($userId: Guid!) {
    account {
      newEmailVerification(model: { userId: $userId })
    }
  }
`;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<
  ResendVerificationEmailMutation,
  ResendVerificationEmailMutationVariables
>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >(ResendVerificationEmailDocument, options);
}
export type ResendVerificationEmailMutationHookResult = ReturnType<
  typeof useResendVerificationEmailMutation
>;
export type ResendVerificationEmailMutationResult =
  Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendVerificationEmailMutation,
  ResendVerificationEmailMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($model: Input_ResetPasswordModel!) {
    account {
      resetPassword(model: $model) {
        refreshToken
        refreshTokenExpiry
        token
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($model: Input_VerifyEmailModel!) {
    account {
      verifyEmail(model: $model) {
        refreshToken
        refreshTokenExpiry
        token
      }
    }
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options,
  );
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult =
  Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const BounceRateChartDocument = gql`
  query BounceRateChart(
    $propertyId: Guid!
    $date: Input_DateRangeWithIntervalFilters!
    $filters: Input_QueryFilters
  ) {
    series {
      bounceRate(propertyId: $propertyId, date: $date, filters: $filters) {
        interval
        data {
          dateTime
          value
        }
      }
    }
  }
`;

/**
 * __useBounceRateChartQuery__
 *
 * To run a query within a React component, call `useBounceRateChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useBounceRateChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBounceRateChartQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBounceRateChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    BounceRateChartQuery,
    BounceRateChartQueryVariables
  > &
    (
      | { variables: BounceRateChartQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BounceRateChartQuery, BounceRateChartQueryVariables>(
    BounceRateChartDocument,
    options,
  );
}
export function useBounceRateChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BounceRateChartQuery,
    BounceRateChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BounceRateChartQuery,
    BounceRateChartQueryVariables
  >(BounceRateChartDocument, options);
}
export function useBounceRateChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BounceRateChartQuery,
    BounceRateChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BounceRateChartQuery,
    BounceRateChartQueryVariables
  >(BounceRateChartDocument, options);
}
export type BounceRateChartQueryHookResult = ReturnType<
  typeof useBounceRateChartQuery
>;
export type BounceRateChartLazyQueryHookResult = ReturnType<
  typeof useBounceRateChartLazyQuery
>;
export type BounceRateChartSuspenseQueryHookResult = ReturnType<
  typeof useBounceRateChartSuspenseQuery
>;
export type BounceRateChartQueryResult = Apollo.QueryResult<
  BounceRateChartQuery,
  BounceRateChartQueryVariables
>;
export const MetricsDocument = gql`
  query Metrics(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    metrics {
      all(propertyId: $propertyId, date: $date, filters: $filters) {
        visitors
        visitorsPrevious
        visitorsChange
        sessions
        sessionsPrevious
        sessionsChange
        bounceRate
        bounceRatePrevious
        bounceRateChange
        sessionDuration
        sessionDurationPrevious
        sessionDurationChange
      }
    }
  }
`;

/**
 * __useMetricsQuery__
 *
 * To run a query within a React component, call `useMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<MetricsQuery, MetricsQueryVariables> &
    ({ variables: MetricsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetricsQuery, MetricsQueryVariables>(
    MetricsDocument,
    options,
  );
}
export function useMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetricsQuery,
    MetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetricsQuery, MetricsQueryVariables>(
    MetricsDocument,
    options,
  );
}
export function useMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MetricsQuery,
    MetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MetricsQuery, MetricsQueryVariables>(
    MetricsDocument,
    options,
  );
}
export type MetricsQueryHookResult = ReturnType<typeof useMetricsQuery>;
export type MetricsLazyQueryHookResult = ReturnType<typeof useMetricsLazyQuery>;
export type MetricsSuspenseQueryHookResult = ReturnType<
  typeof useMetricsSuspenseQuery
>;
export type MetricsQueryResult = Apollo.QueryResult<
  MetricsQuery,
  MetricsQueryVariables
>;
export const SessionDurationChartDocument = gql`
  query SessionDurationChart(
    $propertyId: Guid!
    $date: Input_DateRangeWithIntervalFilters!
    $filters: Input_QueryFilters
  ) {
    series {
      sessionDuration(propertyId: $propertyId, date: $date, filters: $filters) {
        interval
        data {
          dateTime
          value
        }
      }
    }
  }
`;

/**
 * __useSessionDurationChartQuery__
 *
 * To run a query within a React component, call `useSessionDurationChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionDurationChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionDurationChartQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSessionDurationChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionDurationChartQuery,
    SessionDurationChartQueryVariables
  > &
    (
      | { variables: SessionDurationChartQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SessionDurationChartQuery,
    SessionDurationChartQueryVariables
  >(SessionDurationChartDocument, options);
}
export function useSessionDurationChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionDurationChartQuery,
    SessionDurationChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SessionDurationChartQuery,
    SessionDurationChartQueryVariables
  >(SessionDurationChartDocument, options);
}
export function useSessionDurationChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SessionDurationChartQuery,
    SessionDurationChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SessionDurationChartQuery,
    SessionDurationChartQueryVariables
  >(SessionDurationChartDocument, options);
}
export type SessionDurationChartQueryHookResult = ReturnType<
  typeof useSessionDurationChartQuery
>;
export type SessionDurationChartLazyQueryHookResult = ReturnType<
  typeof useSessionDurationChartLazyQuery
>;
export type SessionDurationChartSuspenseQueryHookResult = ReturnType<
  typeof useSessionDurationChartSuspenseQuery
>;
export type SessionDurationChartQueryResult = Apollo.QueryResult<
  SessionDurationChartQuery,
  SessionDurationChartQueryVariables
>;
export const SessionsChartDocument = gql`
  query SessionsChart(
    $propertyId: Guid!
    $date: Input_DateRangeWithIntervalFilters!
    $filters: Input_QueryFilters
  ) {
    series {
      sessionCount(propertyId: $propertyId, date: $date, filters: $filters) {
        interval
        data {
          dateTime
          value
        }
      }
    }
  }
`;

/**
 * __useSessionsChartQuery__
 *
 * To run a query within a React component, call `useSessionsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsChartQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSessionsChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    SessionsChartQuery,
    SessionsChartQueryVariables
  > &
    (
      | { variables: SessionsChartQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionsChartQuery, SessionsChartQueryVariables>(
    SessionsChartDocument,
    options,
  );
}
export function useSessionsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionsChartQuery,
    SessionsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionsChartQuery, SessionsChartQueryVariables>(
    SessionsChartDocument,
    options,
  );
}
export function useSessionsChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SessionsChartQuery,
    SessionsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SessionsChartQuery,
    SessionsChartQueryVariables
  >(SessionsChartDocument, options);
}
export type SessionsChartQueryHookResult = ReturnType<
  typeof useSessionsChartQuery
>;
export type SessionsChartLazyQueryHookResult = ReturnType<
  typeof useSessionsChartLazyQuery
>;
export type SessionsChartSuspenseQueryHookResult = ReturnType<
  typeof useSessionsChartSuspenseQuery
>;
export type SessionsChartQueryResult = Apollo.QueryResult<
  SessionsChartQuery,
  SessionsChartQueryVariables
>;
export const VisitorsChartDocument = gql`
  query VisitorsChart(
    $propertyId: Guid!
    $date: Input_DateRangeWithIntervalFilters!
    $filters: Input_QueryFilters
  ) {
    series {
      visitorCount(propertyId: $propertyId, date: $date, filters: $filters) {
        interval
        data {
          dateTime
          value
        }
      }
    }
  }
`;

/**
 * __useVisitorsChartQuery__
 *
 * To run a query within a React component, call `useVisitorsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorsChartQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitorsChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitorsChartQuery,
    VisitorsChartQueryVariables
  > &
    (
      | { variables: VisitorsChartQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VisitorsChartQuery, VisitorsChartQueryVariables>(
    VisitorsChartDocument,
    options,
  );
}
export function useVisitorsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorsChartQuery,
    VisitorsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VisitorsChartQuery, VisitorsChartQueryVariables>(
    VisitorsChartDocument,
    options,
  );
}
export function useVisitorsChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    VisitorsChartQuery,
    VisitorsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VisitorsChartQuery,
    VisitorsChartQueryVariables
  >(VisitorsChartDocument, options);
}
export type VisitorsChartQueryHookResult = ReturnType<
  typeof useVisitorsChartQuery
>;
export type VisitorsChartLazyQueryHookResult = ReturnType<
  typeof useVisitorsChartLazyQuery
>;
export type VisitorsChartSuspenseQueryHookResult = ReturnType<
  typeof useVisitorsChartSuspenseQuery
>;
export type VisitorsChartQueryResult = Apollo.QueryResult<
  VisitorsChartQuery,
  VisitorsChartQueryVariables
>;
export const CountriesLookupDocument = gql`
  query CountriesLookup {
    lookups {
      countries {
        code
        name
      }
    }
  }
`;

/**
 * __useCountriesLookupQuery__
 *
 * To run a query within a React component, call `useCountriesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesLookupQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountriesLookupQuery,
    CountriesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesLookupQuery, CountriesLookupQueryVariables>(
    CountriesLookupDocument,
    options,
  );
}
export function useCountriesLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountriesLookupQuery,
    CountriesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountriesLookupQuery,
    CountriesLookupQueryVariables
  >(CountriesLookupDocument, options);
}
export function useCountriesLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CountriesLookupQuery,
    CountriesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CountriesLookupQuery,
    CountriesLookupQueryVariables
  >(CountriesLookupDocument, options);
}
export type CountriesLookupQueryHookResult = ReturnType<
  typeof useCountriesLookupQuery
>;
export type CountriesLookupLazyQueryHookResult = ReturnType<
  typeof useCountriesLookupLazyQuery
>;
export type CountriesLookupSuspenseQueryHookResult = ReturnType<
  typeof useCountriesLookupSuspenseQuery
>;
export type CountriesLookupQueryResult = Apollo.QueryResult<
  CountriesLookupQuery,
  CountriesLookupQueryVariables
>;
export const PagesLookupDocument = gql`
  query PagesLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      pages(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __usePagesLookupQuery__
 *
 * To run a query within a React component, call `usePagesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePagesLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    PagesLookupQuery,
    PagesLookupQueryVariables
  > &
    (
      | { variables: PagesLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PagesLookupQuery, PagesLookupQueryVariables>(
    PagesLookupDocument,
    options,
  );
}
export function usePagesLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PagesLookupQuery,
    PagesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PagesLookupQuery, PagesLookupQueryVariables>(
    PagesLookupDocument,
    options,
  );
}
export function usePagesLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PagesLookupQuery,
    PagesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PagesLookupQuery, PagesLookupQueryVariables>(
    PagesLookupDocument,
    options,
  );
}
export type PagesLookupQueryHookResult = ReturnType<typeof usePagesLookupQuery>;
export type PagesLookupLazyQueryHookResult = ReturnType<
  typeof usePagesLookupLazyQuery
>;
export type PagesLookupSuspenseQueryHookResult = ReturnType<
  typeof usePagesLookupSuspenseQuery
>;
export type PagesLookupQueryResult = Apollo.QueryResult<
  PagesLookupQuery,
  PagesLookupQueryVariables
>;
export const ReferrersLookupDocument = gql`
  query ReferrersLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      referrers(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useReferrersLookupQuery__
 *
 * To run a query within a React component, call `useReferrersLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferrersLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferrersLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useReferrersLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferrersLookupQuery,
    ReferrersLookupQueryVariables
  > &
    (
      | { variables: ReferrersLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferrersLookupQuery, ReferrersLookupQueryVariables>(
    ReferrersLookupDocument,
    options,
  );
}
export function useReferrersLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferrersLookupQuery,
    ReferrersLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferrersLookupQuery,
    ReferrersLookupQueryVariables
  >(ReferrersLookupDocument, options);
}
export function useReferrersLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReferrersLookupQuery,
    ReferrersLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferrersLookupQuery,
    ReferrersLookupQueryVariables
  >(ReferrersLookupDocument, options);
}
export type ReferrersLookupQueryHookResult = ReturnType<
  typeof useReferrersLookupQuery
>;
export type ReferrersLookupLazyQueryHookResult = ReturnType<
  typeof useReferrersLookupLazyQuery
>;
export type ReferrersLookupSuspenseQueryHookResult = ReturnType<
  typeof useReferrersLookupSuspenseQuery
>;
export type ReferrersLookupQueryResult = Apollo.QueryResult<
  ReferrersLookupQuery,
  ReferrersLookupQueryVariables
>;
export const SourcesLookupDocument = gql`
  query SourcesLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      sources(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useSourcesLookupQuery__
 *
 * To run a query within a React component, call `useSourcesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcesLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useSourcesLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    SourcesLookupQuery,
    SourcesLookupQueryVariables
  > &
    (
      | { variables: SourcesLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SourcesLookupQuery, SourcesLookupQueryVariables>(
    SourcesLookupDocument,
    options,
  );
}
export function useSourcesLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SourcesLookupQuery,
    SourcesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SourcesLookupQuery, SourcesLookupQueryVariables>(
    SourcesLookupDocument,
    options,
  );
}
export function useSourcesLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SourcesLookupQuery,
    SourcesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SourcesLookupQuery,
    SourcesLookupQueryVariables
  >(SourcesLookupDocument, options);
}
export type SourcesLookupQueryHookResult = ReturnType<
  typeof useSourcesLookupQuery
>;
export type SourcesLookupLazyQueryHookResult = ReturnType<
  typeof useSourcesLookupLazyQuery
>;
export type SourcesLookupSuspenseQueryHookResult = ReturnType<
  typeof useSourcesLookupSuspenseQuery
>;
export type SourcesLookupQueryResult = Apollo.QueryResult<
  SourcesLookupQuery,
  SourcesLookupQueryVariables
>;
export const ScreenSizesLookupDocument = gql`
  query ScreenSizesLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      screenSizes(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useScreenSizesLookupQuery__
 *
 * To run a query within a React component, call `useScreenSizesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenSizesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenSizesLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useScreenSizesLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenSizesLookupQuery,
    ScreenSizesLookupQueryVariables
  > &
    (
      | { variables: ScreenSizesLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreenSizesLookupQuery,
    ScreenSizesLookupQueryVariables
  >(ScreenSizesLookupDocument, options);
}
export function useScreenSizesLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenSizesLookupQuery,
    ScreenSizesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreenSizesLookupQuery,
    ScreenSizesLookupQueryVariables
  >(ScreenSizesLookupDocument, options);
}
export function useScreenSizesLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ScreenSizesLookupQuery,
    ScreenSizesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ScreenSizesLookupQuery,
    ScreenSizesLookupQueryVariables
  >(ScreenSizesLookupDocument, options);
}
export type ScreenSizesLookupQueryHookResult = ReturnType<
  typeof useScreenSizesLookupQuery
>;
export type ScreenSizesLookupLazyQueryHookResult = ReturnType<
  typeof useScreenSizesLookupLazyQuery
>;
export type ScreenSizesLookupSuspenseQueryHookResult = ReturnType<
  typeof useScreenSizesLookupSuspenseQuery
>;
export type ScreenSizesLookupQueryResult = Apollo.QueryResult<
  ScreenSizesLookupQuery,
  ScreenSizesLookupQueryVariables
>;
export const OperatingSystemsLookupDocument = gql`
  query OperatingSystemsLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      operatingSystems(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useOperatingSystemsLookupQuery__
 *
 * To run a query within a React component, call `useOperatingSystemsLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatingSystemsLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatingSystemsLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useOperatingSystemsLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatingSystemsLookupQuery,
    OperatingSystemsLookupQueryVariables
  > &
    (
      | { variables: OperatingSystemsLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OperatingSystemsLookupQuery,
    OperatingSystemsLookupQueryVariables
  >(OperatingSystemsLookupDocument, options);
}
export function useOperatingSystemsLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatingSystemsLookupQuery,
    OperatingSystemsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OperatingSystemsLookupQuery,
    OperatingSystemsLookupQueryVariables
  >(OperatingSystemsLookupDocument, options);
}
export function useOperatingSystemsLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OperatingSystemsLookupQuery,
    OperatingSystemsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OperatingSystemsLookupQuery,
    OperatingSystemsLookupQueryVariables
  >(OperatingSystemsLookupDocument, options);
}
export type OperatingSystemsLookupQueryHookResult = ReturnType<
  typeof useOperatingSystemsLookupQuery
>;
export type OperatingSystemsLookupLazyQueryHookResult = ReturnType<
  typeof useOperatingSystemsLookupLazyQuery
>;
export type OperatingSystemsLookupSuspenseQueryHookResult = ReturnType<
  typeof useOperatingSystemsLookupSuspenseQuery
>;
export type OperatingSystemsLookupQueryResult = Apollo.QueryResult<
  OperatingSystemsLookupQuery,
  OperatingSystemsLookupQueryVariables
>;
export const BrowsersLookupDocument = gql`
  query BrowsersLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      browsers(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useBrowsersLookupQuery__
 *
 * To run a query within a React component, call `useBrowsersLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowsersLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowsersLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBrowsersLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrowsersLookupQuery,
    BrowsersLookupQueryVariables
  > &
    (
      | { variables: BrowsersLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrowsersLookupQuery, BrowsersLookupQueryVariables>(
    BrowsersLookupDocument,
    options,
  );
}
export function useBrowsersLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrowsersLookupQuery,
    BrowsersLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrowsersLookupQuery, BrowsersLookupQueryVariables>(
    BrowsersLookupDocument,
    options,
  );
}
export function useBrowsersLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BrowsersLookupQuery,
    BrowsersLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BrowsersLookupQuery,
    BrowsersLookupQueryVariables
  >(BrowsersLookupDocument, options);
}
export type BrowsersLookupQueryHookResult = ReturnType<
  typeof useBrowsersLookupQuery
>;
export type BrowsersLookupLazyQueryHookResult = ReturnType<
  typeof useBrowsersLookupLazyQuery
>;
export type BrowsersLookupSuspenseQueryHookResult = ReturnType<
  typeof useBrowsersLookupSuspenseQuery
>;
export type BrowsersLookupQueryResult = Apollo.QueryResult<
  BrowsersLookupQuery,
  BrowsersLookupQueryVariables
>;
export const BrowserVersionsLookupDocument = gql`
  query BrowserVersionsLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      browserVersions(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useBrowserVersionsLookupQuery__
 *
 * To run a query within a React component, call `useBrowserVersionsLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowserVersionsLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowserVersionsLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBrowserVersionsLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrowserVersionsLookupQuery,
    BrowserVersionsLookupQueryVariables
  > &
    (
      | { variables: BrowserVersionsLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BrowserVersionsLookupQuery,
    BrowserVersionsLookupQueryVariables
  >(BrowserVersionsLookupDocument, options);
}
export function useBrowserVersionsLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrowserVersionsLookupQuery,
    BrowserVersionsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrowserVersionsLookupQuery,
    BrowserVersionsLookupQueryVariables
  >(BrowserVersionsLookupDocument, options);
}
export function useBrowserVersionsLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BrowserVersionsLookupQuery,
    BrowserVersionsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BrowserVersionsLookupQuery,
    BrowserVersionsLookupQueryVariables
  >(BrowserVersionsLookupDocument, options);
}
export type BrowserVersionsLookupQueryHookResult = ReturnType<
  typeof useBrowserVersionsLookupQuery
>;
export type BrowserVersionsLookupLazyQueryHookResult = ReturnType<
  typeof useBrowserVersionsLookupLazyQuery
>;
export type BrowserVersionsLookupSuspenseQueryHookResult = ReturnType<
  typeof useBrowserVersionsLookupSuspenseQuery
>;
export type BrowserVersionsLookupQueryResult = Apollo.QueryResult<
  BrowserVersionsLookupQuery,
  BrowserVersionsLookupQueryVariables
>;
export const UtmMediumsLookupDocument = gql`
  query UtmMediumsLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      utmMediums(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useUtmMediumsLookupQuery__
 *
 * To run a query within a React component, call `useUtmMediumsLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmMediumsLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmMediumsLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUtmMediumsLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmMediumsLookupQuery,
    UtmMediumsLookupQueryVariables
  > &
    (
      | { variables: UtmMediumsLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtmMediumsLookupQuery, UtmMediumsLookupQueryVariables>(
    UtmMediumsLookupDocument,
    options,
  );
}
export function useUtmMediumsLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmMediumsLookupQuery,
    UtmMediumsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmMediumsLookupQuery,
    UtmMediumsLookupQueryVariables
  >(UtmMediumsLookupDocument, options);
}
export function useUtmMediumsLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmMediumsLookupQuery,
    UtmMediumsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmMediumsLookupQuery,
    UtmMediumsLookupQueryVariables
  >(UtmMediumsLookupDocument, options);
}
export type UtmMediumsLookupQueryHookResult = ReturnType<
  typeof useUtmMediumsLookupQuery
>;
export type UtmMediumsLookupLazyQueryHookResult = ReturnType<
  typeof useUtmMediumsLookupLazyQuery
>;
export type UtmMediumsLookupSuspenseQueryHookResult = ReturnType<
  typeof useUtmMediumsLookupSuspenseQuery
>;
export type UtmMediumsLookupQueryResult = Apollo.QueryResult<
  UtmMediumsLookupQuery,
  UtmMediumsLookupQueryVariables
>;
export const UtmSourcesLookupDocument = gql`
  query UtmSourcesLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      utmSources(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useUtmSourcesLookupQuery__
 *
 * To run a query within a React component, call `useUtmSourcesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmSourcesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmSourcesLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUtmSourcesLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmSourcesLookupQuery,
    UtmSourcesLookupQueryVariables
  > &
    (
      | { variables: UtmSourcesLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtmSourcesLookupQuery, UtmSourcesLookupQueryVariables>(
    UtmSourcesLookupDocument,
    options,
  );
}
export function useUtmSourcesLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmSourcesLookupQuery,
    UtmSourcesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmSourcesLookupQuery,
    UtmSourcesLookupQueryVariables
  >(UtmSourcesLookupDocument, options);
}
export function useUtmSourcesLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmSourcesLookupQuery,
    UtmSourcesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmSourcesLookupQuery,
    UtmSourcesLookupQueryVariables
  >(UtmSourcesLookupDocument, options);
}
export type UtmSourcesLookupQueryHookResult = ReturnType<
  typeof useUtmSourcesLookupQuery
>;
export type UtmSourcesLookupLazyQueryHookResult = ReturnType<
  typeof useUtmSourcesLookupLazyQuery
>;
export type UtmSourcesLookupSuspenseQueryHookResult = ReturnType<
  typeof useUtmSourcesLookupSuspenseQuery
>;
export type UtmSourcesLookupQueryResult = Apollo.QueryResult<
  UtmSourcesLookupQuery,
  UtmSourcesLookupQueryVariables
>;
export const UtmCampaignsLookupDocument = gql`
  query UtmCampaignsLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      utmCampaigns(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useUtmCampaignsLookupQuery__
 *
 * To run a query within a React component, call `useUtmCampaignsLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmCampaignsLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmCampaignsLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUtmCampaignsLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmCampaignsLookupQuery,
    UtmCampaignsLookupQueryVariables
  > &
    (
      | { variables: UtmCampaignsLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UtmCampaignsLookupQuery,
    UtmCampaignsLookupQueryVariables
  >(UtmCampaignsLookupDocument, options);
}
export function useUtmCampaignsLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmCampaignsLookupQuery,
    UtmCampaignsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmCampaignsLookupQuery,
    UtmCampaignsLookupQueryVariables
  >(UtmCampaignsLookupDocument, options);
}
export function useUtmCampaignsLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmCampaignsLookupQuery,
    UtmCampaignsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmCampaignsLookupQuery,
    UtmCampaignsLookupQueryVariables
  >(UtmCampaignsLookupDocument, options);
}
export type UtmCampaignsLookupQueryHookResult = ReturnType<
  typeof useUtmCampaignsLookupQuery
>;
export type UtmCampaignsLookupLazyQueryHookResult = ReturnType<
  typeof useUtmCampaignsLookupLazyQuery
>;
export type UtmCampaignsLookupSuspenseQueryHookResult = ReturnType<
  typeof useUtmCampaignsLookupSuspenseQuery
>;
export type UtmCampaignsLookupQueryResult = Apollo.QueryResult<
  UtmCampaignsLookupQuery,
  UtmCampaignsLookupQueryVariables
>;
export const UtmContentLookupDocument = gql`
  query UtmContentLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      utmContent(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useUtmContentLookupQuery__
 *
 * To run a query within a React component, call `useUtmContentLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmContentLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmContentLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUtmContentLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmContentLookupQuery,
    UtmContentLookupQueryVariables
  > &
    (
      | { variables: UtmContentLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtmContentLookupQuery, UtmContentLookupQueryVariables>(
    UtmContentLookupDocument,
    options,
  );
}
export function useUtmContentLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmContentLookupQuery,
    UtmContentLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmContentLookupQuery,
    UtmContentLookupQueryVariables
  >(UtmContentLookupDocument, options);
}
export function useUtmContentLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmContentLookupQuery,
    UtmContentLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmContentLookupQuery,
    UtmContentLookupQueryVariables
  >(UtmContentLookupDocument, options);
}
export type UtmContentLookupQueryHookResult = ReturnType<
  typeof useUtmContentLookupQuery
>;
export type UtmContentLookupLazyQueryHookResult = ReturnType<
  typeof useUtmContentLookupLazyQuery
>;
export type UtmContentLookupSuspenseQueryHookResult = ReturnType<
  typeof useUtmContentLookupSuspenseQuery
>;
export type UtmContentLookupQueryResult = Apollo.QueryResult<
  UtmContentLookupQuery,
  UtmContentLookupQueryVariables
>;
export const UtmTermsLookupDocument = gql`
  query UtmTermsLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      utmTerms(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useUtmTermsLookupQuery__
 *
 * To run a query within a React component, call `useUtmTermsLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmTermsLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmTermsLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUtmTermsLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmTermsLookupQuery,
    UtmTermsLookupQueryVariables
  > &
    (
      | { variables: UtmTermsLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtmTermsLookupQuery, UtmTermsLookupQueryVariables>(
    UtmTermsLookupDocument,
    options,
  );
}
export function useUtmTermsLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmTermsLookupQuery,
    UtmTermsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UtmTermsLookupQuery, UtmTermsLookupQueryVariables>(
    UtmTermsLookupDocument,
    options,
  );
}
export function useUtmTermsLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmTermsLookupQuery,
    UtmTermsLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmTermsLookupQuery,
    UtmTermsLookupQueryVariables
  >(UtmTermsLookupDocument, options);
}
export type UtmTermsLookupQueryHookResult = ReturnType<
  typeof useUtmTermsLookupQuery
>;
export type UtmTermsLookupLazyQueryHookResult = ReturnType<
  typeof useUtmTermsLookupLazyQuery
>;
export type UtmTermsLookupSuspenseQueryHookResult = ReturnType<
  typeof useUtmTermsLookupSuspenseQuery
>;
export type UtmTermsLookupQueryResult = Apollo.QueryResult<
  UtmTermsLookupQuery,
  UtmTermsLookupQueryVariables
>;
export const DevicesLookupDocument = gql`
  query DevicesLookup(
    $search: String
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
  ) {
    lookups {
      devices(search: $search, propertyId: $propertyId, date: $date)
    }
  }
`;

/**
 * __useDevicesLookupQuery__
 *
 * To run a query within a React component, call `useDevicesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDevicesLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    DevicesLookupQuery,
    DevicesLookupQueryVariables
  > &
    (
      | { variables: DevicesLookupQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevicesLookupQuery, DevicesLookupQueryVariables>(
    DevicesLookupDocument,
    options,
  );
}
export function useDevicesLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DevicesLookupQuery,
    DevicesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevicesLookupQuery, DevicesLookupQueryVariables>(
    DevicesLookupDocument,
    options,
  );
}
export function useDevicesLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DevicesLookupQuery,
    DevicesLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DevicesLookupQuery,
    DevicesLookupQueryVariables
  >(DevicesLookupDocument, options);
}
export type DevicesLookupQueryHookResult = ReturnType<
  typeof useDevicesLookupQuery
>;
export type DevicesLookupLazyQueryHookResult = ReturnType<
  typeof useDevicesLookupLazyQuery
>;
export type DevicesLookupSuspenseQueryHookResult = ReturnType<
  typeof useDevicesLookupSuspenseQuery
>;
export type DevicesLookupQueryResult = Apollo.QueryResult<
  DevicesLookupQuery,
  DevicesLookupQueryVariables
>;
export const UtMcampaignsStatsDocument = gql`
  query UTMcampaignsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      utmcampaigns(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useUtMcampaignsStatsQuery__
 *
 * To run a query within a React component, call `useUtMcampaignsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtMcampaignsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtMcampaignsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtMcampaignsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtMcampaignsStatsQuery,
    UtMcampaignsStatsQueryVariables
  > &
    (
      | { variables: UtMcampaignsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UtMcampaignsStatsQuery,
    UtMcampaignsStatsQueryVariables
  >(UtMcampaignsStatsDocument, options);
}
export function useUtMcampaignsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtMcampaignsStatsQuery,
    UtMcampaignsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtMcampaignsStatsQuery,
    UtMcampaignsStatsQueryVariables
  >(UtMcampaignsStatsDocument, options);
}
export function useUtMcampaignsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtMcampaignsStatsQuery,
    UtMcampaignsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtMcampaignsStatsQuery,
    UtMcampaignsStatsQueryVariables
  >(UtMcampaignsStatsDocument, options);
}
export type UtMcampaignsStatsQueryHookResult = ReturnType<
  typeof useUtMcampaignsStatsQuery
>;
export type UtMcampaignsStatsLazyQueryHookResult = ReturnType<
  typeof useUtMcampaignsStatsLazyQuery
>;
export type UtMcampaignsStatsSuspenseQueryHookResult = ReturnType<
  typeof useUtMcampaignsStatsSuspenseQuery
>;
export type UtMcampaignsStatsQueryResult = Apollo.QueryResult<
  UtMcampaignsStatsQuery,
  UtMcampaignsStatsQueryVariables
>;
export const UtMcontentsStatsDocument = gql`
  query UTMcontentsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      utmcontents(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useUtMcontentsStatsQuery__
 *
 * To run a query within a React component, call `useUtMcontentsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtMcontentsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtMcontentsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtMcontentsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtMcontentsStatsQuery,
    UtMcontentsStatsQueryVariables
  > &
    (
      | { variables: UtMcontentsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtMcontentsStatsQuery, UtMcontentsStatsQueryVariables>(
    UtMcontentsStatsDocument,
    options,
  );
}
export function useUtMcontentsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtMcontentsStatsQuery,
    UtMcontentsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtMcontentsStatsQuery,
    UtMcontentsStatsQueryVariables
  >(UtMcontentsStatsDocument, options);
}
export function useUtMcontentsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtMcontentsStatsQuery,
    UtMcontentsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtMcontentsStatsQuery,
    UtMcontentsStatsQueryVariables
  >(UtMcontentsStatsDocument, options);
}
export type UtMcontentsStatsQueryHookResult = ReturnType<
  typeof useUtMcontentsStatsQuery
>;
export type UtMcontentsStatsLazyQueryHookResult = ReturnType<
  typeof useUtMcontentsStatsLazyQuery
>;
export type UtMcontentsStatsSuspenseQueryHookResult = ReturnType<
  typeof useUtMcontentsStatsSuspenseQuery
>;
export type UtMcontentsStatsQueryResult = Apollo.QueryResult<
  UtMcontentsStatsQuery,
  UtMcontentsStatsQueryVariables
>;
export const UtMmediumsStatsDocument = gql`
  query UTMmediumsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      utmmediums(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useUtMmediumsStatsQuery__
 *
 * To run a query within a React component, call `useUtMmediumsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtMmediumsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtMmediumsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtMmediumsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtMmediumsStatsQuery,
    UtMmediumsStatsQueryVariables
  > &
    (
      | { variables: UtMmediumsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtMmediumsStatsQuery, UtMmediumsStatsQueryVariables>(
    UtMmediumsStatsDocument,
    options,
  );
}
export function useUtMmediumsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtMmediumsStatsQuery,
    UtMmediumsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtMmediumsStatsQuery,
    UtMmediumsStatsQueryVariables
  >(UtMmediumsStatsDocument, options);
}
export function useUtMmediumsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtMmediumsStatsQuery,
    UtMmediumsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtMmediumsStatsQuery,
    UtMmediumsStatsQueryVariables
  >(UtMmediumsStatsDocument, options);
}
export type UtMmediumsStatsQueryHookResult = ReturnType<
  typeof useUtMmediumsStatsQuery
>;
export type UtMmediumsStatsLazyQueryHookResult = ReturnType<
  typeof useUtMmediumsStatsLazyQuery
>;
export type UtMmediumsStatsSuspenseQueryHookResult = ReturnType<
  typeof useUtMmediumsStatsSuspenseQuery
>;
export type UtMmediumsStatsQueryResult = Apollo.QueryResult<
  UtMmediumsStatsQuery,
  UtMmediumsStatsQueryVariables
>;
export const UtMsourcesStatsDocument = gql`
  query UTMsourcesStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      utmsources(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useUtMsourcesStatsQuery__
 *
 * To run a query within a React component, call `useUtMsourcesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtMsourcesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtMsourcesStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtMsourcesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtMsourcesStatsQuery,
    UtMsourcesStatsQueryVariables
  > &
    (
      | { variables: UtMsourcesStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtMsourcesStatsQuery, UtMsourcesStatsQueryVariables>(
    UtMsourcesStatsDocument,
    options,
  );
}
export function useUtMsourcesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtMsourcesStatsQuery,
    UtMsourcesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtMsourcesStatsQuery,
    UtMsourcesStatsQueryVariables
  >(UtMsourcesStatsDocument, options);
}
export function useUtMsourcesStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtMsourcesStatsQuery,
    UtMsourcesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtMsourcesStatsQuery,
    UtMsourcesStatsQueryVariables
  >(UtMsourcesStatsDocument, options);
}
export type UtMsourcesStatsQueryHookResult = ReturnType<
  typeof useUtMsourcesStatsQuery
>;
export type UtMsourcesStatsLazyQueryHookResult = ReturnType<
  typeof useUtMsourcesStatsLazyQuery
>;
export type UtMsourcesStatsSuspenseQueryHookResult = ReturnType<
  typeof useUtMsourcesStatsSuspenseQuery
>;
export type UtMsourcesStatsQueryResult = Apollo.QueryResult<
  UtMsourcesStatsQuery,
  UtMsourcesStatsQueryVariables
>;
export const ConversionsDocument = gql`
  query Conversions(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    conversions {
      completions(propertyId: $propertyId, date: $date, filters: $filters) {
        ...ConversionFields
      }
    }
  }
  ${ConversionFieldsFragmentDoc}
`;

/**
 * __useConversionsQuery__
 *
 * To run a query within a React component, call `useConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useConversionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConversionsQuery,
    ConversionsQueryVariables
  > &
    (
      | { variables: ConversionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConversionsQuery, ConversionsQueryVariables>(
    ConversionsDocument,
    options,
  );
}
export function useConversionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversionsQuery,
    ConversionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConversionsQuery, ConversionsQueryVariables>(
    ConversionsDocument,
    options,
  );
}
export function useConversionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConversionsQuery,
    ConversionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ConversionsQuery, ConversionsQueryVariables>(
    ConversionsDocument,
    options,
  );
}
export type ConversionsQueryHookResult = ReturnType<typeof useConversionsQuery>;
export type ConversionsLazyQueryHookResult = ReturnType<
  typeof useConversionsLazyQuery
>;
export type ConversionsSuspenseQueryHookResult = ReturnType<
  typeof useConversionsSuspenseQuery
>;
export type ConversionsQueryResult = Apollo.QueryResult<
  ConversionsQuery,
  ConversionsQueryVariables
>;
export const CitiesStatsDocument = gql`
  query CitiesStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      cities(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useCitiesStatsQuery__
 *
 * To run a query within a React component, call `useCitiesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCitiesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CitiesStatsQuery,
    CitiesStatsQueryVariables
  > &
    (
      | { variables: CitiesStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CitiesStatsQuery, CitiesStatsQueryVariables>(
    CitiesStatsDocument,
    options,
  );
}
export function useCitiesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CitiesStatsQuery,
    CitiesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CitiesStatsQuery, CitiesStatsQueryVariables>(
    CitiesStatsDocument,
    options,
  );
}
export function useCitiesStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CitiesStatsQuery,
    CitiesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CitiesStatsQuery, CitiesStatsQueryVariables>(
    CitiesStatsDocument,
    options,
  );
}
export type CitiesStatsQueryHookResult = ReturnType<typeof useCitiesStatsQuery>;
export type CitiesStatsLazyQueryHookResult = ReturnType<
  typeof useCitiesStatsLazyQuery
>;
export type CitiesStatsSuspenseQueryHookResult = ReturnType<
  typeof useCitiesStatsSuspenseQuery
>;
export type CitiesStatsQueryResult = Apollo.QueryResult<
  CitiesStatsQuery,
  CitiesStatsQueryVariables
>;
export const CountriesStatsDocument = gql`
  query CountriesStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      countries(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useCountriesStatsQuery__
 *
 * To run a query within a React component, call `useCountriesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountriesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountriesStatsQuery,
    CountriesStatsQueryVariables
  > &
    (
      | { variables: CountriesStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesStatsQuery, CountriesStatsQueryVariables>(
    CountriesStatsDocument,
    options,
  );
}
export function useCountriesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountriesStatsQuery,
    CountriesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountriesStatsQuery, CountriesStatsQueryVariables>(
    CountriesStatsDocument,
    options,
  );
}
export function useCountriesStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CountriesStatsQuery,
    CountriesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CountriesStatsQuery,
    CountriesStatsQueryVariables
  >(CountriesStatsDocument, options);
}
export type CountriesStatsQueryHookResult = ReturnType<
  typeof useCountriesStatsQuery
>;
export type CountriesStatsLazyQueryHookResult = ReturnType<
  typeof useCountriesStatsLazyQuery
>;
export type CountriesStatsSuspenseQueryHookResult = ReturnType<
  typeof useCountriesStatsSuspenseQuery
>;
export type CountriesStatsQueryResult = Apollo.QueryResult<
  CountriesStatsQuery,
  CountriesStatsQueryVariables
>;
export const RegionsStatsDocument = gql`
  query RegionsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      regions(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useRegionsStatsQuery__
 *
 * To run a query within a React component, call `useRegionsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRegionsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegionsStatsQuery,
    RegionsStatsQueryVariables
  > &
    (
      | { variables: RegionsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionsStatsQuery, RegionsStatsQueryVariables>(
    RegionsStatsDocument,
    options,
  );
}
export function useRegionsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionsStatsQuery,
    RegionsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionsStatsQuery, RegionsStatsQueryVariables>(
    RegionsStatsDocument,
    options,
  );
}
export function useRegionsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RegionsStatsQuery,
    RegionsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RegionsStatsQuery, RegionsStatsQueryVariables>(
    RegionsStatsDocument,
    options,
  );
}
export type RegionsStatsQueryHookResult = ReturnType<
  typeof useRegionsStatsQuery
>;
export type RegionsStatsLazyQueryHookResult = ReturnType<
  typeof useRegionsStatsLazyQuery
>;
export type RegionsStatsSuspenseQueryHookResult = ReturnType<
  typeof useRegionsStatsSuspenseQuery
>;
export type RegionsStatsQueryResult = Apollo.QueryResult<
  RegionsStatsQuery,
  RegionsStatsQueryVariables
>;
export const ExitPagesStatsDocument = gql`
  query ExitPagesStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      exitPages(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useExitPagesStatsQuery__
 *
 * To run a query within a React component, call `useExitPagesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExitPagesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExitPagesStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExitPagesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExitPagesStatsQuery,
    ExitPagesStatsQueryVariables
  > &
    (
      | { variables: ExitPagesStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExitPagesStatsQuery, ExitPagesStatsQueryVariables>(
    ExitPagesStatsDocument,
    options,
  );
}
export function useExitPagesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExitPagesStatsQuery,
    ExitPagesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExitPagesStatsQuery, ExitPagesStatsQueryVariables>(
    ExitPagesStatsDocument,
    options,
  );
}
export function useExitPagesStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExitPagesStatsQuery,
    ExitPagesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExitPagesStatsQuery,
    ExitPagesStatsQueryVariables
  >(ExitPagesStatsDocument, options);
}
export type ExitPagesStatsQueryHookResult = ReturnType<
  typeof useExitPagesStatsQuery
>;
export type ExitPagesStatsLazyQueryHookResult = ReturnType<
  typeof useExitPagesStatsLazyQuery
>;
export type ExitPagesStatsSuspenseQueryHookResult = ReturnType<
  typeof useExitPagesStatsSuspenseQuery
>;
export type ExitPagesStatsQueryResult = Apollo.QueryResult<
  ExitPagesStatsQuery,
  ExitPagesStatsQueryVariables
>;
export const EntryPagesStatsDocument = gql`
  query EntryPagesStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      entryPages(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useEntryPagesStatsQuery__
 *
 * To run a query within a React component, call `useEntryPagesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryPagesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryPagesStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEntryPagesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EntryPagesStatsQuery,
    EntryPagesStatsQueryVariables
  > &
    (
      | { variables: EntryPagesStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntryPagesStatsQuery, EntryPagesStatsQueryVariables>(
    EntryPagesStatsDocument,
    options,
  );
}
export function useEntryPagesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EntryPagesStatsQuery,
    EntryPagesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EntryPagesStatsQuery,
    EntryPagesStatsQueryVariables
  >(EntryPagesStatsDocument, options);
}
export function useEntryPagesStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EntryPagesStatsQuery,
    EntryPagesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EntryPagesStatsQuery,
    EntryPagesStatsQueryVariables
  >(EntryPagesStatsDocument, options);
}
export type EntryPagesStatsQueryHookResult = ReturnType<
  typeof useEntryPagesStatsQuery
>;
export type EntryPagesStatsLazyQueryHookResult = ReturnType<
  typeof useEntryPagesStatsLazyQuery
>;
export type EntryPagesStatsSuspenseQueryHookResult = ReturnType<
  typeof useEntryPagesStatsSuspenseQuery
>;
export type EntryPagesStatsQueryResult = Apollo.QueryResult<
  EntryPagesStatsQuery,
  EntryPagesStatsQueryVariables
>;
export const PageViewsStatsDocument = gql`
  query PageViewsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      pageViews(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __usePageViewsStatsQuery__
 *
 * To run a query within a React component, call `usePageViewsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageViewsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageViewsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePageViewsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PageViewsStatsQuery,
    PageViewsStatsQueryVariables
  > &
    (
      | { variables: PageViewsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageViewsStatsQuery, PageViewsStatsQueryVariables>(
    PageViewsStatsDocument,
    options,
  );
}
export function usePageViewsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageViewsStatsQuery,
    PageViewsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageViewsStatsQuery, PageViewsStatsQueryVariables>(
    PageViewsStatsDocument,
    options,
  );
}
export function usePageViewsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PageViewsStatsQuery,
    PageViewsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PageViewsStatsQuery,
    PageViewsStatsQueryVariables
  >(PageViewsStatsDocument, options);
}
export type PageViewsStatsQueryHookResult = ReturnType<
  typeof usePageViewsStatsQuery
>;
export type PageViewsStatsLazyQueryHookResult = ReturnType<
  typeof usePageViewsStatsLazyQuery
>;
export type PageViewsStatsSuspenseQueryHookResult = ReturnType<
  typeof usePageViewsStatsSuspenseQuery
>;
export type PageViewsStatsQueryResult = Apollo.QueryResult<
  PageViewsStatsQuery,
  PageViewsStatsQueryVariables
>;
export const BrowsersStatsDocument = gql`
  query BrowsersStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      browsers(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useBrowsersStatsQuery__
 *
 * To run a query within a React component, call `useBrowsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrowsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrowsersStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBrowsersStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrowsersStatsQuery,
    BrowsersStatsQueryVariables
  > &
    (
      | { variables: BrowsersStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrowsersStatsQuery, BrowsersStatsQueryVariables>(
    BrowsersStatsDocument,
    options,
  );
}
export function useBrowsersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrowsersStatsQuery,
    BrowsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrowsersStatsQuery, BrowsersStatsQueryVariables>(
    BrowsersStatsDocument,
    options,
  );
}
export function useBrowsersStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BrowsersStatsQuery,
    BrowsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BrowsersStatsQuery,
    BrowsersStatsQueryVariables
  >(BrowsersStatsDocument, options);
}
export type BrowsersStatsQueryHookResult = ReturnType<
  typeof useBrowsersStatsQuery
>;
export type BrowsersStatsLazyQueryHookResult = ReturnType<
  typeof useBrowsersStatsLazyQuery
>;
export type BrowsersStatsSuspenseQueryHookResult = ReturnType<
  typeof useBrowsersStatsSuspenseQuery
>;
export type BrowsersStatsQueryResult = Apollo.QueryResult<
  BrowsersStatsQuery,
  BrowsersStatsQueryVariables
>;
export const DevicesStatsDocument = gql`
  query DevicesStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      devices(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useDevicesStatsQuery__
 *
 * To run a query within a React component, call `useDevicesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDevicesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DevicesStatsQuery,
    DevicesStatsQueryVariables
  > &
    (
      | { variables: DevicesStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevicesStatsQuery, DevicesStatsQueryVariables>(
    DevicesStatsDocument,
    options,
  );
}
export function useDevicesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DevicesStatsQuery,
    DevicesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevicesStatsQuery, DevicesStatsQueryVariables>(
    DevicesStatsDocument,
    options,
  );
}
export function useDevicesStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DevicesStatsQuery,
    DevicesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DevicesStatsQuery, DevicesStatsQueryVariables>(
    DevicesStatsDocument,
    options,
  );
}
export type DevicesStatsQueryHookResult = ReturnType<
  typeof useDevicesStatsQuery
>;
export type DevicesStatsLazyQueryHookResult = ReturnType<
  typeof useDevicesStatsLazyQuery
>;
export type DevicesStatsSuspenseQueryHookResult = ReturnType<
  typeof useDevicesStatsSuspenseQuery
>;
export type DevicesStatsQueryResult = Apollo.QueryResult<
  DevicesStatsQuery,
  DevicesStatsQueryVariables
>;
export const OperatingSystemsStatsDocument = gql`
  query OperatingSystemsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      operatingSystems(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useOperatingSystemsStatsQuery__
 *
 * To run a query within a React component, call `useOperatingSystemsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatingSystemsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatingSystemsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOperatingSystemsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatingSystemsStatsQuery,
    OperatingSystemsStatsQueryVariables
  > &
    (
      | { variables: OperatingSystemsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OperatingSystemsStatsQuery,
    OperatingSystemsStatsQueryVariables
  >(OperatingSystemsStatsDocument, options);
}
export function useOperatingSystemsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatingSystemsStatsQuery,
    OperatingSystemsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OperatingSystemsStatsQuery,
    OperatingSystemsStatsQueryVariables
  >(OperatingSystemsStatsDocument, options);
}
export function useOperatingSystemsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OperatingSystemsStatsQuery,
    OperatingSystemsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OperatingSystemsStatsQuery,
    OperatingSystemsStatsQueryVariables
  >(OperatingSystemsStatsDocument, options);
}
export type OperatingSystemsStatsQueryHookResult = ReturnType<
  typeof useOperatingSystemsStatsQuery
>;
export type OperatingSystemsStatsLazyQueryHookResult = ReturnType<
  typeof useOperatingSystemsStatsLazyQuery
>;
export type OperatingSystemsStatsSuspenseQueryHookResult = ReturnType<
  typeof useOperatingSystemsStatsSuspenseQuery
>;
export type OperatingSystemsStatsQueryResult = Apollo.QueryResult<
  OperatingSystemsStatsQuery,
  OperatingSystemsStatsQueryVariables
>;
export const ChannelsStatsDocument = gql`
  query ChannelsStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      channels(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useChannelsStatsQuery__
 *
 * To run a query within a React component, call `useChannelsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useChannelsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChannelsStatsQuery,
    ChannelsStatsQueryVariables
  > &
    (
      | { variables: ChannelsStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChannelsStatsQuery, ChannelsStatsQueryVariables>(
    ChannelsStatsDocument,
    options,
  );
}
export function useChannelsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChannelsStatsQuery,
    ChannelsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChannelsStatsQuery, ChannelsStatsQueryVariables>(
    ChannelsStatsDocument,
    options,
  );
}
export function useChannelsStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ChannelsStatsQuery,
    ChannelsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ChannelsStatsQuery,
    ChannelsStatsQueryVariables
  >(ChannelsStatsDocument, options);
}
export type ChannelsStatsQueryHookResult = ReturnType<
  typeof useChannelsStatsQuery
>;
export type ChannelsStatsLazyQueryHookResult = ReturnType<
  typeof useChannelsStatsLazyQuery
>;
export type ChannelsStatsSuspenseQueryHookResult = ReturnType<
  typeof useChannelsStatsSuspenseQuery
>;
export type ChannelsStatsQueryResult = Apollo.QueryResult<
  ChannelsStatsQuery,
  ChannelsStatsQueryVariables
>;
export const ReferrersStatsDocument = gql`
  query ReferrersStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      referrers(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useReferrersStatsQuery__
 *
 * To run a query within a React component, call `useReferrersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferrersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferrersStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReferrersStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferrersStatsQuery,
    ReferrersStatsQueryVariables
  > &
    (
      | { variables: ReferrersStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferrersStatsQuery, ReferrersStatsQueryVariables>(
    ReferrersStatsDocument,
    options,
  );
}
export function useReferrersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferrersStatsQuery,
    ReferrersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferrersStatsQuery, ReferrersStatsQueryVariables>(
    ReferrersStatsDocument,
    options,
  );
}
export function useReferrersStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReferrersStatsQuery,
    ReferrersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferrersStatsQuery,
    ReferrersStatsQueryVariables
  >(ReferrersStatsDocument, options);
}
export type ReferrersStatsQueryHookResult = ReturnType<
  typeof useReferrersStatsQuery
>;
export type ReferrersStatsLazyQueryHookResult = ReturnType<
  typeof useReferrersStatsLazyQuery
>;
export type ReferrersStatsSuspenseQueryHookResult = ReturnType<
  typeof useReferrersStatsSuspenseQuery
>;
export type ReferrersStatsQueryResult = Apollo.QueryResult<
  ReferrersStatsQuery,
  ReferrersStatsQueryVariables
>;
export const TrafficStatsDocument = gql`
  query TrafficStats(
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    lists {
      sources(
        propertyId: $propertyId
        date: $date
        filters: $filters
        take: 1000
      ) {
        ...ListEntryFields
      }
    }
  }
  ${ListEntryFieldsFragmentDoc}
`;

/**
 * __useTrafficStatsQuery__
 *
 * To run a query within a React component, call `useTrafficStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrafficStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrafficStatsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTrafficStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrafficStatsQuery,
    TrafficStatsQueryVariables
  > &
    (
      | { variables: TrafficStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrafficStatsQuery, TrafficStatsQueryVariables>(
    TrafficStatsDocument,
    options,
  );
}
export function useTrafficStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrafficStatsQuery,
    TrafficStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrafficStatsQuery, TrafficStatsQueryVariables>(
    TrafficStatsDocument,
    options,
  );
}
export function useTrafficStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TrafficStatsQuery,
    TrafficStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TrafficStatsQuery, TrafficStatsQueryVariables>(
    TrafficStatsDocument,
    options,
  );
}
export type TrafficStatsQueryHookResult = ReturnType<
  typeof useTrafficStatsQuery
>;
export type TrafficStatsLazyQueryHookResult = ReturnType<
  typeof useTrafficStatsLazyQuery
>;
export type TrafficStatsSuspenseQueryHookResult = ReturnType<
  typeof useTrafficStatsSuspenseQuery
>;
export type TrafficStatsQueryResult = Apollo.QueryResult<
  TrafficStatsQuery,
  TrafficStatsQueryVariables
>;
export const AcceptInvitationDocument = gql`
  mutation AcceptInvitation($model: Input_AcceptInvitationModel!) {
    subscriptions {
      acceptInvitation(model: $model) {
        token
      }
    }
  }
`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >(AcceptInvitationDocument, options);
}
export type AcceptInvitationMutationHookResult = ReturnType<
  typeof useAcceptInvitationMutation
>;
export type AcceptInvitationMutationResult =
  Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>;
export const ConversionFetchDocument = gql`
  query ConversionFetch($propertyId: Guid!, $conversionId: Guid!) {
    conversions {
      get(propertyId: $propertyId, conversionId: $conversionId) {
        id
        propertyId
        name
        path
        eventType
        operator
        createdOn
      }
    }
  }
`;

/**
 * __useConversionFetchQuery__
 *
 * To run a query within a React component, call `useConversionFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionFetchQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      conversionId: // value for 'conversionId'
 *   },
 * });
 */
export function useConversionFetchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConversionFetchQuery,
    ConversionFetchQueryVariables
  > &
    (
      | { variables: ConversionFetchQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConversionFetchQuery, ConversionFetchQueryVariables>(
    ConversionFetchDocument,
    options,
  );
}
export function useConversionFetchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversionFetchQuery,
    ConversionFetchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConversionFetchQuery,
    ConversionFetchQueryVariables
  >(ConversionFetchDocument, options);
}
export function useConversionFetchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConversionFetchQuery,
    ConversionFetchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ConversionFetchQuery,
    ConversionFetchQueryVariables
  >(ConversionFetchDocument, options);
}
export type ConversionFetchQueryHookResult = ReturnType<
  typeof useConversionFetchQuery
>;
export type ConversionFetchLazyQueryHookResult = ReturnType<
  typeof useConversionFetchLazyQuery
>;
export type ConversionFetchSuspenseQueryHookResult = ReturnType<
  typeof useConversionFetchSuspenseQuery
>;
export type ConversionFetchQueryResult = Apollo.QueryResult<
  ConversionFetchQuery,
  ConversionFetchQueryVariables
>;
export const CreateConversionDocument = gql`
  mutation CreateConversion($model: Input_CreateConversionModel!) {
    conversions {
      create(model: $model) {
        id
        propertyId
        name
        path
        eventType
        operator
        createdOn
      }
    }
  }
`;
export type CreateConversionMutationFn = Apollo.MutationFunction<
  CreateConversionMutation,
  CreateConversionMutationVariables
>;

/**
 * __useCreateConversionMutation__
 *
 * To run a mutation, you first call `useCreateConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversionMutation, { data, loading, error }] = useCreateConversionMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useCreateConversionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConversionMutation,
    CreateConversionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConversionMutation,
    CreateConversionMutationVariables
  >(CreateConversionDocument, options);
}
export type CreateConversionMutationHookResult = ReturnType<
  typeof useCreateConversionMutation
>;
export type CreateConversionMutationResult =
  Apollo.MutationResult<CreateConversionMutation>;
export type CreateConversionMutationOptions = Apollo.BaseMutationOptions<
  CreateConversionMutation,
  CreateConversionMutationVariables
>;
export const DeleteConversionDocument = gql`
  mutation DeleteConversion($model: Input_DeleteConversionModel!) {
    conversions {
      delete(model: $model)
    }
  }
`;
export type DeleteConversionMutationFn = Apollo.MutationFunction<
  DeleteConversionMutation,
  DeleteConversionMutationVariables
>;

/**
 * __useDeleteConversionMutation__
 *
 * To run a mutation, you first call `useDeleteConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversionMutation, { data, loading, error }] = useDeleteConversionMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useDeleteConversionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConversionMutation,
    DeleteConversionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConversionMutation,
    DeleteConversionMutationVariables
  >(DeleteConversionDocument, options);
}
export type DeleteConversionMutationHookResult = ReturnType<
  typeof useDeleteConversionMutation
>;
export type DeleteConversionMutationResult =
  Apollo.MutationResult<DeleteConversionMutation>;
export type DeleteConversionMutationOptions = Apollo.BaseMutationOptions<
  DeleteConversionMutation,
  DeleteConversionMutationVariables
>;
export const UpdateConversionDocument = gql`
  mutation UpdateConversion($model: Input_ConversionModel!) {
    conversions {
      update(model: $model) {
        id
        propertyId
        name
        path
        eventType
        operator
        createdOn
      }
    }
  }
`;
export type UpdateConversionMutationFn = Apollo.MutationFunction<
  UpdateConversionMutation,
  UpdateConversionMutationVariables
>;

/**
 * __useUpdateConversionMutation__
 *
 * To run a mutation, you first call `useUpdateConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversionMutation, { data, loading, error }] = useUpdateConversionMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdateConversionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConversionMutation,
    UpdateConversionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConversionMutation,
    UpdateConversionMutationVariables
  >(UpdateConversionDocument, options);
}
export type UpdateConversionMutationHookResult = ReturnType<
  typeof useUpdateConversionMutation
>;
export type UpdateConversionMutationResult =
  Apollo.MutationResult<UpdateConversionMutation>;
export type UpdateConversionMutationOptions = Apollo.BaseMutationOptions<
  UpdateConversionMutation,
  UpdateConversionMutationVariables
>;
export const UpdateFunnelDocument = gql`
  mutation UpdateFunnel($model: Input_FunnelModel!) {
    funnels {
      update(model: $model) {
        id
      }
    }
  }
`;
export type UpdateFunnelMutationFn = Apollo.MutationFunction<
  UpdateFunnelMutation,
  UpdateFunnelMutationVariables
>;

/**
 * __useUpdateFunnelMutation__
 *
 * To run a mutation, you first call `useUpdateFunnelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFunnelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFunnelMutation, { data, loading, error }] = useUpdateFunnelMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdateFunnelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFunnelMutation,
    UpdateFunnelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFunnelMutation,
    UpdateFunnelMutationVariables
  >(UpdateFunnelDocument, options);
}
export type UpdateFunnelMutationHookResult = ReturnType<
  typeof useUpdateFunnelMutation
>;
export type UpdateFunnelMutationResult =
  Apollo.MutationResult<UpdateFunnelMutation>;
export type UpdateFunnelMutationOptions = Apollo.BaseMutationOptions<
  UpdateFunnelMutation,
  UpdateFunnelMutationVariables
>;
export const FunnelDocument = gql`
  query Funnel($funnelId: Guid!, $propertyId: Guid!) {
    funnels {
      get(id: $funnelId, propertyId: $propertyId) {
        ...Funnel
      }
    }
  }
  ${FunnelFragmentDoc}
`;

/**
 * __useFunnelQuery__
 *
 * To run a query within a React component, call `useFunnelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunnelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunnelQuery({
 *   variables: {
 *      funnelId: // value for 'funnelId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useFunnelQuery(
  baseOptions: Apollo.QueryHookOptions<FunnelQuery, FunnelQueryVariables> &
    ({ variables: FunnelQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FunnelQuery, FunnelQueryVariables>(
    FunnelDocument,
    options,
  );
}
export function useFunnelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FunnelQuery, FunnelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FunnelQuery, FunnelQueryVariables>(
    FunnelDocument,
    options,
  );
}
export function useFunnelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FunnelQuery,
    FunnelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FunnelQuery, FunnelQueryVariables>(
    FunnelDocument,
    options,
  );
}
export type FunnelQueryHookResult = ReturnType<typeof useFunnelQuery>;
export type FunnelLazyQueryHookResult = ReturnType<typeof useFunnelLazyQuery>;
export type FunnelSuspenseQueryHookResult = ReturnType<
  typeof useFunnelSuspenseQuery
>;
export type FunnelQueryResult = Apollo.QueryResult<
  FunnelQuery,
  FunnelQueryVariables
>;
export const CreateFunnelDocument = gql`
  mutation CreateFunnel($model: Input_CreateFunnelModel!) {
    funnels {
      create(model: $model) {
        id
        propertyId
        name
        description
        createdBy
        createdOn
        steps {
          name
          path
          eventType
          operator
        }
      }
    }
  }
`;
export type CreateFunnelMutationFn = Apollo.MutationFunction<
  CreateFunnelMutation,
  CreateFunnelMutationVariables
>;

/**
 * __useCreateFunnelMutation__
 *
 * To run a mutation, you first call `useCreateFunnelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFunnelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFunnelMutation, { data, loading, error }] = useCreateFunnelMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useCreateFunnelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFunnelMutation,
    CreateFunnelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFunnelMutation,
    CreateFunnelMutationVariables
  >(CreateFunnelDocument, options);
}
export type CreateFunnelMutationHookResult = ReturnType<
  typeof useCreateFunnelMutation
>;
export type CreateFunnelMutationResult =
  Apollo.MutationResult<CreateFunnelMutation>;
export type CreateFunnelMutationOptions = Apollo.BaseMutationOptions<
  CreateFunnelMutation,
  CreateFunnelMutationVariables
>;
export const DeleteFunnelDocument = gql`
  mutation DeleteFunnel($model: Input_DeleteFunnelModel!) {
    funnels {
      delete(model: $model)
    }
  }
`;
export type DeleteFunnelMutationFn = Apollo.MutationFunction<
  DeleteFunnelMutation,
  DeleteFunnelMutationVariables
>;

/**
 * __useDeleteFunnelMutation__
 *
 * To run a mutation, you first call `useDeleteFunnelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFunnelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFunnelMutation, { data, loading, error }] = useDeleteFunnelMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useDeleteFunnelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFunnelMutation,
    DeleteFunnelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFunnelMutation,
    DeleteFunnelMutationVariables
  >(DeleteFunnelDocument, options);
}
export type DeleteFunnelMutationHookResult = ReturnType<
  typeof useDeleteFunnelMutation
>;
export type DeleteFunnelMutationResult =
  Apollo.MutationResult<DeleteFunnelMutation>;
export type DeleteFunnelMutationOptions = Apollo.BaseMutationOptions<
  DeleteFunnelMutation,
  DeleteFunnelMutationVariables
>;
export const FunnelsDocument = gql`
  query Funnels($propertyId: Guid!) {
    funnels {
      getAll(propertyId: $propertyId) {
        ...Funnel
      }
    }
  }
  ${FunnelFragmentDoc}
`;

/**
 * __useFunnelsQuery__
 *
 * To run a query within a React component, call `useFunnelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunnelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunnelsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useFunnelsQuery(
  baseOptions: Apollo.QueryHookOptions<FunnelsQuery, FunnelsQueryVariables> &
    ({ variables: FunnelsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FunnelsQuery, FunnelsQueryVariables>(
    FunnelsDocument,
    options,
  );
}
export function useFunnelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FunnelsQuery,
    FunnelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FunnelsQuery, FunnelsQueryVariables>(
    FunnelsDocument,
    options,
  );
}
export function useFunnelsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FunnelsQuery,
    FunnelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FunnelsQuery, FunnelsQueryVariables>(
    FunnelsDocument,
    options,
  );
}
export type FunnelsQueryHookResult = ReturnType<typeof useFunnelsQuery>;
export type FunnelsLazyQueryHookResult = ReturnType<typeof useFunnelsLazyQuery>;
export type FunnelsSuspenseQueryHookResult = ReturnType<
  typeof useFunnelsSuspenseQuery
>;
export type FunnelsQueryResult = Apollo.QueryResult<
  FunnelsQuery,
  FunnelsQueryVariables
>;
export const InviteTeamMembersDocument = gql`
  mutation InviteTeamMembers($model: Input_InviteUsersModel!) {
    subscriptions {
      inviteUsers(model: $model)
    }
  }
`;
export type InviteTeamMembersMutationFn = Apollo.MutationFunction<
  InviteTeamMembersMutation,
  InviteTeamMembersMutationVariables
>;

/**
 * __useInviteTeamMembersMutation__
 *
 * To run a mutation, you first call `useInviteTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeamMembersMutation, { data, loading, error }] = useInviteTeamMembersMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useInviteTeamMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteTeamMembersMutation,
    InviteTeamMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteTeamMembersMutation,
    InviteTeamMembersMutationVariables
  >(InviteTeamMembersDocument, options);
}
export type InviteTeamMembersMutationHookResult = ReturnType<
  typeof useInviteTeamMembersMutation
>;
export type InviteTeamMembersMutationResult =
  Apollo.MutationResult<InviteTeamMembersMutation>;
export type InviteTeamMembersMutationOptions = Apollo.BaseMutationOptions<
  InviteTeamMembersMutation,
  InviteTeamMembersMutationVariables
>;
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($model: Input_ChangePasswordModel!) {
    profile {
      changePassword(model: $model)
    }
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<
  typeof useUpdatePasswordMutation
>;
export type UpdatePasswordMutationResult =
  Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
export const AllPropertiesDocument = gql`
  query AllProperties {
    properties {
      getAll {
        id
        name
      }
    }
  }
`;

/**
 * __useAllPropertiesQuery__
 *
 * To run a query within a React component, call `useAllPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllPropertiesQuery,
    AllPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPropertiesQuery, AllPropertiesQueryVariables>(
    AllPropertiesDocument,
    options,
  );
}
export function useAllPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPropertiesQuery,
    AllPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllPropertiesQuery, AllPropertiesQueryVariables>(
    AllPropertiesDocument,
    options,
  );
}
export function useAllPropertiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllPropertiesQuery,
    AllPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AllPropertiesQuery,
    AllPropertiesQueryVariables
  >(AllPropertiesDocument, options);
}
export type AllPropertiesQueryHookResult = ReturnType<
  typeof useAllPropertiesQuery
>;
export type AllPropertiesLazyQueryHookResult = ReturnType<
  typeof useAllPropertiesLazyQuery
>;
export type AllPropertiesSuspenseQueryHookResult = ReturnType<
  typeof useAllPropertiesSuspenseQuery
>;
export type AllPropertiesQueryResult = Apollo.QueryResult<
  AllPropertiesQuery,
  AllPropertiesQueryVariables
>;
export const PropertyFetchDocument = gql`
  query PropertyFetch($propertyId: Guid!) {
    properties {
      get(propertyId: $propertyId) {
        id
        subscriptionId
        name
        host
        timezone
        firstSentDataOn
        additionalHosts
        defaultApiToken
      }
    }
  }
`;

/**
 * __usePropertyFetchQuery__
 *
 * To run a query within a React component, call `usePropertyFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyFetchQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function usePropertyFetchQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyFetchQuery,
    PropertyFetchQueryVariables
  > &
    (
      | { variables: PropertyFetchQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyFetchQuery, PropertyFetchQueryVariables>(
    PropertyFetchDocument,
    options,
  );
}
export function usePropertyFetchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyFetchQuery,
    PropertyFetchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyFetchQuery, PropertyFetchQueryVariables>(
    PropertyFetchDocument,
    options,
  );
}
export function usePropertyFetchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PropertyFetchQuery,
    PropertyFetchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PropertyFetchQuery,
    PropertyFetchQueryVariables
  >(PropertyFetchDocument, options);
}
export type PropertyFetchQueryHookResult = ReturnType<
  typeof usePropertyFetchQuery
>;
export type PropertyFetchLazyQueryHookResult = ReturnType<
  typeof usePropertyFetchLazyQuery
>;
export type PropertyFetchSuspenseQueryHookResult = ReturnType<
  typeof usePropertyFetchSuspenseQuery
>;
export type PropertyFetchQueryResult = Apollo.QueryResult<
  PropertyFetchQuery,
  PropertyFetchQueryVariables
>;
export const PropertyFirstSentDocument = gql`
  query PropertyFirstSent($propertyId: Guid!) {
    properties {
      get(propertyId: $propertyId) {
        id
        firstSentDataOn
      }
    }
  }
`;

/**
 * __usePropertyFirstSentQuery__
 *
 * To run a query within a React component, call `usePropertyFirstSentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyFirstSentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyFirstSentQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function usePropertyFirstSentQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyFirstSentQuery,
    PropertyFirstSentQueryVariables
  > &
    (
      | { variables: PropertyFirstSentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PropertyFirstSentQuery,
    PropertyFirstSentQueryVariables
  >(PropertyFirstSentDocument, options);
}
export function usePropertyFirstSentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyFirstSentQuery,
    PropertyFirstSentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PropertyFirstSentQuery,
    PropertyFirstSentQueryVariables
  >(PropertyFirstSentDocument, options);
}
export function usePropertyFirstSentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PropertyFirstSentQuery,
    PropertyFirstSentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PropertyFirstSentQuery,
    PropertyFirstSentQueryVariables
  >(PropertyFirstSentDocument, options);
}
export type PropertyFirstSentQueryHookResult = ReturnType<
  typeof usePropertyFirstSentQuery
>;
export type PropertyFirstSentLazyQueryHookResult = ReturnType<
  typeof usePropertyFirstSentLazyQuery
>;
export type PropertyFirstSentSuspenseQueryHookResult = ReturnType<
  typeof usePropertyFirstSentSuspenseQuery
>;
export type PropertyFirstSentQueryResult = Apollo.QueryResult<
  PropertyFirstSentQuery,
  PropertyFirstSentQueryVariables
>;
export const CreatePropertyDocument = gql`
  mutation CreateProperty($model: Input_CreatePropertyModel!) {
    properties {
      create(model: $model) {
        id
        name
        host
        firstSentDataOn
        additionalHosts
        timezone
      }
    }
  }
`;
export type CreatePropertyMutationFn = Apollo.MutationFunction<
  CreatePropertyMutation,
  CreatePropertyMutationVariables
>;

/**
 * __useCreatePropertyMutation__
 *
 * To run a mutation, you first call `useCreatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyMutation, { data, loading, error }] = useCreatePropertyMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useCreatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePropertyMutation,
    CreatePropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePropertyMutation,
    CreatePropertyMutationVariables
  >(CreatePropertyDocument, options);
}
export type CreatePropertyMutationHookResult = ReturnType<
  typeof useCreatePropertyMutation
>;
export type CreatePropertyMutationResult =
  Apollo.MutationResult<CreatePropertyMutation>;
export type CreatePropertyMutationOptions = Apollo.BaseMutationOptions<
  CreatePropertyMutation,
  CreatePropertyMutationVariables
>;
export const DeletePropertyDocument = gql`
  mutation DeleteProperty($model: Input_DeletePropertyModel!) {
    properties {
      delete(model: $model)
    }
  }
`;
export type DeletePropertyMutationFn = Apollo.MutationFunction<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useDeletePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyMutation,
    DeletePropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePropertyMutation,
    DeletePropertyMutationVariables
  >(DeletePropertyDocument, options);
}
export type DeletePropertyMutationHookResult = ReturnType<
  typeof useDeletePropertyMutation
>;
export type DeletePropertyMutationResult =
  Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyMutation,
  DeletePropertyMutationVariables
>;
export const UpdatePropertyDocument = gql`
  mutation UpdateProperty($model: Input_PropertyModel!) {
    properties {
      update(model: $model) {
        id
        name
        host
        firstSentDataOn
        additionalHosts
        timezone
      }
    }
  }
`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >(UpdatePropertyDocument, options);
}
export type UpdatePropertyMutationHookResult = ReturnType<
  typeof useUpdatePropertyMutation
>;
export type UpdatePropertyMutationResult =
  Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const SubscriptionPlanDocument = gql`
  query SubscriptionPlan($subscriptionId: Guid!) {
    subscriptions {
      plan(model: { subscriptionId: $subscriptionId }) {
        id
        currency
        unitAmount
        interval
        entitlementType
        entitlementAmount
        managePlanUrl
        upgradePlanUrl
        availablePlans {
          currency
          unitAmount
          interval
          entitlementType
          entitlementAmount
          purchaseUrl
        }
      }
    }
  }
`;

/**
 * __useSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlanQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useSubscriptionPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionPlanQuery,
    SubscriptionPlanQueryVariables
  > &
    (
      | { variables: SubscriptionPlanQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(
    SubscriptionPlanDocument,
    options,
  );
}
export function useSubscriptionPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionPlanQuery,
    SubscriptionPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubscriptionPlanQuery,
    SubscriptionPlanQueryVariables
  >(SubscriptionPlanDocument, options);
}
export function useSubscriptionPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubscriptionPlanQuery,
    SubscriptionPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SubscriptionPlanQuery,
    SubscriptionPlanQueryVariables
  >(SubscriptionPlanDocument, options);
}
export type SubscriptionPlanQueryHookResult = ReturnType<
  typeof useSubscriptionPlanQuery
>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<
  typeof useSubscriptionPlanLazyQuery
>;
export type SubscriptionPlanSuspenseQueryHookResult = ReturnType<
  typeof useSubscriptionPlanSuspenseQuery
>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<
  SubscriptionPlanQuery,
  SubscriptionPlanQueryVariables
>;
export const SubscriptionUpdateDocument = gql`
  mutation SubscriptionUpdate($model: Input_UpdateSubscriptionModel!) {
    subscriptions {
      update(model: $model)
    }
  }
`;
export type SubscriptionUpdateMutationFn = Apollo.MutationFunction<
  SubscriptionUpdateMutation,
  SubscriptionUpdateMutationVariables
>;

/**
 * __useSubscriptionUpdateMutation__
 *
 * To run a mutation, you first call `useSubscriptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionUpdateMutation, { data, loading, error }] = useSubscriptionUpdateMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useSubscriptionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscriptionUpdateMutation,
    SubscriptionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscriptionUpdateMutation,
    SubscriptionUpdateMutationVariables
  >(SubscriptionUpdateDocument, options);
}
export type SubscriptionUpdateMutationHookResult = ReturnType<
  typeof useSubscriptionUpdateMutation
>;
export type SubscriptionUpdateMutationResult =
  Apollo.MutationResult<SubscriptionUpdateMutation>;
export type SubscriptionUpdateMutationOptions = Apollo.BaseMutationOptions<
  SubscriptionUpdateMutation,
  SubscriptionUpdateMutationVariables
>;
export const SubscriptionUsageDocument = gql`
  query SubscriptionUsage($model: Input_GetSubscriptionUsageModel!) {
    subscriptions {
      usage(model: $model) {
        id
        totalUsage
        propertyUsage {
          id
          name
          usage
        }
      }
    }
  }
`;

/**
 * __useSubscriptionUsageQuery__
 *
 * To run a query within a React component, call `useSubscriptionUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionUsageQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useSubscriptionUsageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionUsageQuery,
    SubscriptionUsageQueryVariables
  > &
    (
      | { variables: SubscriptionUsageQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubscriptionUsageQuery,
    SubscriptionUsageQueryVariables
  >(SubscriptionUsageDocument, options);
}
export function useSubscriptionUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionUsageQuery,
    SubscriptionUsageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubscriptionUsageQuery,
    SubscriptionUsageQueryVariables
  >(SubscriptionUsageDocument, options);
}
export function useSubscriptionUsageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubscriptionUsageQuery,
    SubscriptionUsageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SubscriptionUsageQuery,
    SubscriptionUsageQueryVariables
  >(SubscriptionUsageDocument, options);
}
export type SubscriptionUsageQueryHookResult = ReturnType<
  typeof useSubscriptionUsageQuery
>;
export type SubscriptionUsageLazyQueryHookResult = ReturnType<
  typeof useSubscriptionUsageLazyQuery
>;
export type SubscriptionUsageSuspenseQueryHookResult = ReturnType<
  typeof useSubscriptionUsageSuspenseQuery
>;
export type SubscriptionUsageQueryResult = Apollo.QueryResult<
  SubscriptionUsageQuery,
  SubscriptionUsageQueryVariables
>;
export const SubscriptionsDocument = gql`
  query Subscriptions {
    subscriptions {
      getAll {
        ...Subscription
      }
    }
  }
  ${SubscriptionFragmentDoc}
`;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(
    SubscriptionsDocument,
    options,
  );
}
export function useSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(
    SubscriptionsDocument,
    options,
  );
}
export function useSubscriptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >(SubscriptionsDocument, options);
}
export type SubscriptionsQueryHookResult = ReturnType<
  typeof useSubscriptionsQuery
>;
export type SubscriptionsLazyQueryHookResult = ReturnType<
  typeof useSubscriptionsLazyQuery
>;
export type SubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useSubscriptionsSuspenseQuery
>;
export type SubscriptionsQueryResult = Apollo.QueryResult<
  SubscriptionsQuery,
  SubscriptionsQueryVariables
>;
export const TeamMemberRemoveDocument = gql`
  mutation TeamMemberRemove($model: Input_RemoveMemberModel!) {
    subscriptions {
      removeMember(model: $model)
    }
  }
`;
export type TeamMemberRemoveMutationFn = Apollo.MutationFunction<
  TeamMemberRemoveMutation,
  TeamMemberRemoveMutationVariables
>;

/**
 * __useTeamMemberRemoveMutation__
 *
 * To run a mutation, you first call `useTeamMemberRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberRemoveMutation, { data, loading, error }] = useTeamMemberRemoveMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useTeamMemberRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamMemberRemoveMutation,
    TeamMemberRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamMemberRemoveMutation,
    TeamMemberRemoveMutationVariables
  >(TeamMemberRemoveDocument, options);
}
export type TeamMemberRemoveMutationHookResult = ReturnType<
  typeof useTeamMemberRemoveMutation
>;
export type TeamMemberRemoveMutationResult =
  Apollo.MutationResult<TeamMemberRemoveMutation>;
export type TeamMemberRemoveMutationOptions = Apollo.BaseMutationOptions<
  TeamMemberRemoveMutation,
  TeamMemberRemoveMutationVariables
>;
export const TeamMemberUpdateDocument = gql`
  mutation TeamMemberUpdate($model: Input_UpdateMemberModel!) {
    subscriptions {
      updateMember(model: $model)
    }
  }
`;
export type TeamMemberUpdateMutationFn = Apollo.MutationFunction<
  TeamMemberUpdateMutation,
  TeamMemberUpdateMutationVariables
>;

/**
 * __useTeamMemberUpdateMutation__
 *
 * To run a mutation, you first call `useTeamMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberUpdateMutation, { data, loading, error }] = useTeamMemberUpdateMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useTeamMemberUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeamMemberUpdateMutation,
    TeamMemberUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TeamMemberUpdateMutation,
    TeamMemberUpdateMutationVariables
  >(TeamMemberUpdateDocument, options);
}
export type TeamMemberUpdateMutationHookResult = ReturnType<
  typeof useTeamMemberUpdateMutation
>;
export type TeamMemberUpdateMutationResult =
  Apollo.MutationResult<TeamMemberUpdateMutation>;
export type TeamMemberUpdateMutationOptions = Apollo.BaseMutationOptions<
  TeamMemberUpdateMutation,
  TeamMemberUpdateMutationVariables
>;
export const TeamMembersDocument = gql`
  query TeamMembers($model: Input_GetSubscriptionMembersModel!) {
    subscriptions {
      listMembers(model: $model) {
        ...TeamMember
      }
    }
  }
  ${TeamMemberFragmentDoc}
`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useTeamMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamMembersQuery,
    TeamMembersQueryVariables
  > &
    (
      | { variables: TeamMembersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options,
  );
}
export function useTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMembersQuery,
    TeamMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options,
  );
}
export function useTeamMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TeamMembersQuery,
    TeamMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options,
  );
}
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<
  typeof useTeamMembersLazyQuery
>;
export type TeamMembersSuspenseQueryHookResult = ReturnType<
  typeof useTeamMembersSuspenseQuery
>;
export type TeamMembersQueryResult = Apollo.QueryResult<
  TeamMembersQuery,
  TeamMembersQueryVariables
>;
export const UserProfileDocument = gql`
  query UserProfile {
    profile {
      get(size: 40) {
        ...UserProfileFields
      }
    }
  }
  ${UserProfileFieldsFragmentDoc}
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options,
  );
}
export function useUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options,
  );
}
export function useUserProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options,
  );
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<
  typeof useUserProfileLazyQuery
>;
export type UserProfileSuspenseQueryHookResult = ReturnType<
  typeof useUserProfileSuspenseQuery
>;
export type UserProfileQueryResult = Apollo.QueryResult<
  UserProfileQuery,
  UserProfileQueryVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($model: Input_UpdateUserProfileModel!) {
    profile {
      update(model: $model)
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const FunnelConversionDocument = gql`
  query FunnelConversion(
    $funnelId: Guid!
    $propertyId: Guid!
    $date: Input_DateRangeFilters!
    $filters: Input_QueryFilters
  ) {
    funnels {
      getConversions(
        id: $funnelId
        propertyId: $propertyId
        date: $date
        filters: $filters
      ) {
        id
        name
        description
        enteredCount
        completedCount
        conversionRate
        steps {
          name
          path
          eventType
          conversions
          conversionRate
        }
      }
    }
  }
`;

/**
 * __useFunnelConversionQuery__
 *
 * To run a query within a React component, call `useFunnelConversionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunnelConversionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunnelConversionQuery({
 *   variables: {
 *      funnelId: // value for 'funnelId'
 *      propertyId: // value for 'propertyId'
 *      date: // value for 'date'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFunnelConversionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FunnelConversionQuery,
    FunnelConversionQueryVariables
  > &
    (
      | { variables: FunnelConversionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FunnelConversionQuery, FunnelConversionQueryVariables>(
    FunnelConversionDocument,
    options,
  );
}
export function useFunnelConversionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FunnelConversionQuery,
    FunnelConversionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FunnelConversionQuery,
    FunnelConversionQueryVariables
  >(FunnelConversionDocument, options);
}
export function useFunnelConversionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FunnelConversionQuery,
    FunnelConversionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FunnelConversionQuery,
    FunnelConversionQueryVariables
  >(FunnelConversionDocument, options);
}
export type FunnelConversionQueryHookResult = ReturnType<
  typeof useFunnelConversionQuery
>;
export type FunnelConversionLazyQueryHookResult = ReturnType<
  typeof useFunnelConversionLazyQuery
>;
export type FunnelConversionSuspenseQueryHookResult = ReturnType<
  typeof useFunnelConversionSuspenseQuery
>;
export type FunnelConversionQueryResult = Apollo.QueryResult<
  FunnelConversionQuery,
  FunnelConversionQueryVariables
>;
export const TimezoneLookupDocument = gql`
  query TimezoneLookup {
    lookups {
      timeZones {
        code
        name
      }
    }
  }
`;

/**
 * __useTimezoneLookupQuery__
 *
 * To run a query within a React component, call `useTimezoneLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezoneLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezoneLookupQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezoneLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimezoneLookupQuery,
    TimezoneLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimezoneLookupQuery, TimezoneLookupQueryVariables>(
    TimezoneLookupDocument,
    options,
  );
}
export function useTimezoneLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimezoneLookupQuery,
    TimezoneLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimezoneLookupQuery, TimezoneLookupQueryVariables>(
    TimezoneLookupDocument,
    options,
  );
}
export function useTimezoneLookupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TimezoneLookupQuery,
    TimezoneLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TimezoneLookupQuery,
    TimezoneLookupQueryVariables
  >(TimezoneLookupDocument, options);
}
export type TimezoneLookupQueryHookResult = ReturnType<
  typeof useTimezoneLookupQuery
>;
export type TimezoneLookupLazyQueryHookResult = ReturnType<
  typeof useTimezoneLookupLazyQuery
>;
export type TimezoneLookupSuspenseQueryHookResult = ReturnType<
  typeof useTimezoneLookupSuspenseQuery
>;
export type TimezoneLookupQueryResult = Apollo.QueryResult<
  TimezoneLookupQuery,
  TimezoneLookupQueryVariables
>;
export type AcceptInvitationResultModelKeySpecifier = (
  | 'token'
  | AcceptInvitationResultModelKeySpecifier
)[];
export type AcceptInvitationResultModelFieldPolicy = {
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvailablePlanModelKeySpecifier = (
  | 'currency'
  | 'entitlementAmount'
  | 'entitlementType'
  | 'interval'
  | 'purchaseUrl'
  | 'unitAmount'
  | AvailablePlanModelKeySpecifier
)[];
export type AvailablePlanModelFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  entitlementAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  entitlementType?: FieldPolicy<any> | FieldReadFunction<any>;
  interval?: FieldPolicy<any> | FieldReadFunction<any>;
  purchaseUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversionCompletionModelKeySpecifier = (
  | 'completionCount'
  | 'completionRate'
  | 'id'
  | 'name'
  | 'propertyId'
  | ConversionCompletionModelKeySpecifier
)[];
export type ConversionCompletionModelFieldPolicy = {
  completionCount?: FieldPolicy<any> | FieldReadFunction<any>;
  completionRate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConversionModelKeySpecifier = (
  | 'createdOn'
  | 'eventType'
  | 'id'
  | 'name'
  | 'operator'
  | 'path'
  | 'propertyId'
  | ConversionModelKeySpecifier
)[];
export type ConversionModelFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  eventType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  path?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DecimalPointKeySpecifier = (
  | 'dateTime'
  | 'value'
  | DecimalPointKeySpecifier
)[];
export type DecimalPointFieldPolicy = {
  dateTime?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FunnelConversionsModelKeySpecifier = (
  | 'completedCount'
  | 'conversionRate'
  | 'description'
  | 'enteredCount'
  | 'id'
  | 'name'
  | 'steps'
  | FunnelConversionsModelKeySpecifier
)[];
export type FunnelConversionsModelFieldPolicy = {
  completedCount?: FieldPolicy<any> | FieldReadFunction<any>;
  conversionRate?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  enteredCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  steps?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FunnelConversionsStepModelKeySpecifier = (
  | 'conversionRate'
  | 'conversions'
  | 'eventType'
  | 'name'
  | 'path'
  | 'stepNumber'
  | FunnelConversionsStepModelKeySpecifier
)[];
export type FunnelConversionsStepModelFieldPolicy = {
  conversionRate?: FieldPolicy<any> | FieldReadFunction<any>;
  conversions?: FieldPolicy<any> | FieldReadFunction<any>;
  eventType?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  path?: FieldPolicy<any> | FieldReadFunction<any>;
  stepNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FunnelModelKeySpecifier = (
  | 'createdBy'
  | 'createdOn'
  | 'description'
  | 'id'
  | 'name'
  | 'propertyId'
  | 'steps'
  | FunnelModelKeySpecifier
)[];
export type FunnelModelFieldPolicy = {
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyId?: FieldPolicy<any> | FieldReadFunction<any>;
  steps?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FunnelStepModelKeySpecifier = (
  | 'eventType'
  | 'name'
  | 'operator'
  | 'path'
  | FunnelStepModelKeySpecifier
)[];
export type FunnelStepModelFieldPolicy = {
  eventType?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  path?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IntegerPointKeySpecifier = (
  | 'dateTime'
  | 'value'
  | IntegerPointKeySpecifier
)[];
export type IntegerPointFieldPolicy = {
  dateTime?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListEntryKeySpecifier = (
  | 'count'
  | 'url'
  | 'value'
  | ListEntryKeySpecifier
)[];
export type ListEntryFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginResultKeySpecifier = ('token' | LoginResultKeySpecifier)[];
export type LoginResultFieldPolicy = {
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginResultModelKeySpecifier = (
  | 'refreshToken'
  | 'refreshTokenExpiry'
  | 'successfullyAddedToTeam'
  | 'token'
  | LoginResultModelKeySpecifier
)[];
export type LoginResultModelFieldPolicy = {
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshTokenExpiry?: FieldPolicy<any> | FieldReadFunction<any>;
  successfullyAddedToTeam?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupValueKeySpecifier = (
  | 'code'
  | 'name'
  | LookupValueKeySpecifier
)[];
export type LookupValueFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'account'
  | 'conversions'
  | 'funnels'
  | 'images'
  | 'profile'
  | 'properties'
  | 'subscriptions'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  conversions?: FieldPolicy<any> | FieldReadFunction<any>;
  funnels?: FieldPolicy<any> | FieldReadFunction<any>;
  images?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  properties?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_AccountKeySpecifier = (
  | 'forgotPassword'
  | 'login'
  | 'logout'
  | 'newEmailVerification'
  | 'refreshToken'
  | 'register'
  | 'resetPassword'
  | 'verifyEmail'
  | Mutation_AccountKeySpecifier
)[];
export type Mutation_AccountFieldPolicy = {
  forgotPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  login?: FieldPolicy<any> | FieldReadFunction<any>;
  logout?: FieldPolicy<any> | FieldReadFunction<any>;
  newEmailVerification?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
  register?: FieldPolicy<any> | FieldReadFunction<any>;
  resetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  verifyEmail?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_ConversionsKeySpecifier = (
  | 'create'
  | 'delete'
  | 'update'
  | Mutation_ConversionsKeySpecifier
)[];
export type Mutation_ConversionsFieldPolicy = {
  create?: FieldPolicy<any> | FieldReadFunction<any>;
  delete?: FieldPolicy<any> | FieldReadFunction<any>;
  update?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_FunnelsKeySpecifier = (
  | 'create'
  | 'delete'
  | 'update'
  | Mutation_FunnelsKeySpecifier
)[];
export type Mutation_FunnelsFieldPolicy = {
  create?: FieldPolicy<any> | FieldReadFunction<any>;
  delete?: FieldPolicy<any> | FieldReadFunction<any>;
  update?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_ImagesKeySpecifier = (
  | 'uploadProfileImage'
  | Mutation_ImagesKeySpecifier
)[];
export type Mutation_ImagesFieldPolicy = {
  uploadProfileImage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_ProfileKeySpecifier = (
  | 'changePassword'
  | 'update'
  | Mutation_ProfileKeySpecifier
)[];
export type Mutation_ProfileFieldPolicy = {
  changePassword?: FieldPolicy<any> | FieldReadFunction<any>;
  update?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_PropertiesKeySpecifier = (
  | 'create'
  | 'delete'
  | 'update'
  | Mutation_PropertiesKeySpecifier
)[];
export type Mutation_PropertiesFieldPolicy = {
  create?: FieldPolicy<any> | FieldReadFunction<any>;
  delete?: FieldPolicy<any> | FieldReadFunction<any>;
  update?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Mutation_SubscriptionsKeySpecifier = (
  | 'acceptInvitation'
  | 'inviteUsers'
  | 'removeMember'
  | 'update'
  | 'updateMember'
  | Mutation_SubscriptionsKeySpecifier
)[];
export type Mutation_SubscriptionsFieldPolicy = {
  acceptInvitation?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  removeMember?: FieldPolicy<any> | FieldReadFunction<any>;
  update?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMember?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyModelKeySpecifier = (
  | 'additionalHosts'
  | 'defaultApiToken'
  | 'firstSentDataOn'
  | 'host'
  | 'id'
  | 'name'
  | 'subscriptionId'
  | 'timezone'
  | PropertyModelKeySpecifier
)[];
export type PropertyModelFieldPolicy = {
  additionalHosts?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultApiToken?: FieldPolicy<any> | FieldReadFunction<any>;
  firstSentDataOn?: FieldPolicy<any> | FieldReadFunction<any>;
  host?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertySummaryModelKeySpecifier = (
  | 'firstSentDataOn'
  | 'id'
  | 'name'
  | 'subscriptionId'
  | PropertySummaryModelKeySpecifier
)[];
export type PropertySummaryModelFieldPolicy = {
  firstSentDataOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PropertyUsageModelKeySpecifier = (
  | 'id'
  | 'name'
  | 'usage'
  | PropertyUsageModelKeySpecifier
)[];
export type PropertyUsageModelFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  usage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'auth'
  | 'conversions'
  | 'funnels'
  | 'lists'
  | 'lookups'
  | 'metrics'
  | 'profile'
  | 'properties'
  | 'series'
  | 'subscriptions'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  auth?: FieldPolicy<any> | FieldReadFunction<any>;
  conversions?: FieldPolicy<any> | FieldReadFunction<any>;
  funnels?: FieldPolicy<any> | FieldReadFunction<any>;
  lists?: FieldPolicy<any> | FieldReadFunction<any>;
  lookups?: FieldPolicy<any> | FieldReadFunction<any>;
  metrics?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  properties?: FieldPolicy<any> | FieldReadFunction<any>;
  series?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_AuthKeySpecifier = ('login' | Query_AuthKeySpecifier)[];
export type Query_AuthFieldPolicy = {
  login?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_ConversionsKeySpecifier = (
  | 'completions'
  | 'get'
  | Query_ConversionsKeySpecifier
)[];
export type Query_ConversionsFieldPolicy = {
  completions?: FieldPolicy<any> | FieldReadFunction<any>;
  get?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_FunnelsKeySpecifier = (
  | 'get'
  | 'getAll'
  | 'getConversions'
  | Query_FunnelsKeySpecifier
)[];
export type Query_FunnelsFieldPolicy = {
  get?: FieldPolicy<any> | FieldReadFunction<any>;
  getAll?: FieldPolicy<any> | FieldReadFunction<any>;
  getConversions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_ListsKeySpecifier = (
  | 'browsers'
  | 'channels'
  | 'cities'
  | 'countries'
  | 'devices'
  | 'entryPages'
  | 'exitPages'
  | 'operatingSystems'
  | 'pageViews'
  | 'referrers'
  | 'regions'
  | 'sources'
  | 'utmcampaigns'
  | 'utmcontents'
  | 'utmmediums'
  | 'utmsources'
  | Query_ListsKeySpecifier
)[];
export type Query_ListsFieldPolicy = {
  browsers?: FieldPolicy<any> | FieldReadFunction<any>;
  channels?: FieldPolicy<any> | FieldReadFunction<any>;
  cities?: FieldPolicy<any> | FieldReadFunction<any>;
  countries?: FieldPolicy<any> | FieldReadFunction<any>;
  devices?: FieldPolicy<any> | FieldReadFunction<any>;
  entryPages?: FieldPolicy<any> | FieldReadFunction<any>;
  exitPages?: FieldPolicy<any> | FieldReadFunction<any>;
  operatingSystems?: FieldPolicy<any> | FieldReadFunction<any>;
  pageViews?: FieldPolicy<any> | FieldReadFunction<any>;
  referrers?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  sources?: FieldPolicy<any> | FieldReadFunction<any>;
  utmcampaigns?: FieldPolicy<any> | FieldReadFunction<any>;
  utmcontents?: FieldPolicy<any> | FieldReadFunction<any>;
  utmmediums?: FieldPolicy<any> | FieldReadFunction<any>;
  utmsources?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_LookupsKeySpecifier = (
  | 'browserVersions'
  | 'browsers'
  | 'channels'
  | 'cities'
  | 'countries'
  | 'deviceModels'
  | 'devices'
  | 'entrypages'
  | 'exitpages'
  | 'operatingSystemVersions'
  | 'operatingSystems'
  | 'pages'
  | 'referrers'
  | 'regions'
  | 'screenSizes'
  | 'sources'
  | 'timeZones'
  | 'utmCampaigns'
  | 'utmContent'
  | 'utmMediums'
  | 'utmSources'
  | 'utmTerms'
  | Query_LookupsKeySpecifier
)[];
export type Query_LookupsFieldPolicy = {
  browserVersions?: FieldPolicy<any> | FieldReadFunction<any>;
  browsers?: FieldPolicy<any> | FieldReadFunction<any>;
  channels?: FieldPolicy<any> | FieldReadFunction<any>;
  cities?: FieldPolicy<any> | FieldReadFunction<any>;
  countries?: FieldPolicy<any> | FieldReadFunction<any>;
  deviceModels?: FieldPolicy<any> | FieldReadFunction<any>;
  devices?: FieldPolicy<any> | FieldReadFunction<any>;
  entrypages?: FieldPolicy<any> | FieldReadFunction<any>;
  exitpages?: FieldPolicy<any> | FieldReadFunction<any>;
  operatingSystemVersions?: FieldPolicy<any> | FieldReadFunction<any>;
  operatingSystems?: FieldPolicy<any> | FieldReadFunction<any>;
  pages?: FieldPolicy<any> | FieldReadFunction<any>;
  referrers?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  screenSizes?: FieldPolicy<any> | FieldReadFunction<any>;
  sources?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZones?: FieldPolicy<any> | FieldReadFunction<any>;
  utmCampaigns?: FieldPolicy<any> | FieldReadFunction<any>;
  utmContent?: FieldPolicy<any> | FieldReadFunction<any>;
  utmMediums?: FieldPolicy<any> | FieldReadFunction<any>;
  utmSources?: FieldPolicy<any> | FieldReadFunction<any>;
  utmTerms?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_MetricsKeySpecifier = ('all' | Query_MetricsKeySpecifier)[];
export type Query_MetricsFieldPolicy = {
  all?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_ProfileKeySpecifier = ('get' | Query_ProfileKeySpecifier)[];
export type Query_ProfileFieldPolicy = {
  get?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_PropertiesKeySpecifier = (
  | 'get'
  | 'getAll'
  | Query_PropertiesKeySpecifier
)[];
export type Query_PropertiesFieldPolicy = {
  get?: FieldPolicy<any> | FieldReadFunction<any>;
  getAll?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_SeriesKeySpecifier = (
  | 'bounceRate'
  | 'sessionCount'
  | 'sessionDuration'
  | 'visitorCount'
  | Query_SeriesKeySpecifier
)[];
export type Query_SeriesFieldPolicy = {
  bounceRate?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionDuration?: FieldPolicy<any> | FieldReadFunction<any>;
  visitorCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Query_SubscriptionsKeySpecifier = (
  | 'getAll'
  | 'listMembers'
  | 'plan'
  | 'usage'
  | Query_SubscriptionsKeySpecifier
)[];
export type Query_SubscriptionsFieldPolicy = {
  getAll?: FieldPolicy<any> | FieldReadFunction<any>;
  listMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  plan?: FieldPolicy<any> | FieldReadFunction<any>;
  usage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionMemberModelKeySpecifier = (
  | 'email'
  | 'fullName'
  | 'isCurrentUser'
  | 'profileImageUrl'
  | 'role'
  | 'userId'
  | SubscriptionMemberModelKeySpecifier
)[];
export type SubscriptionMemberModelFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  isCurrentUser?: FieldPolicy<any> | FieldReadFunction<any>;
  profileImageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionModelKeySpecifier = (
  | 'createdOn'
  | 'id'
  | 'name'
  | 'planType'
  | 'role'
  | SubscriptionModelKeySpecifier
)[];
export type SubscriptionModelFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  planType?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionPlanModelKeySpecifier = (
  | 'availablePlans'
  | 'currency'
  | 'entitlementAmount'
  | 'entitlementType'
  | 'id'
  | 'interval'
  | 'managePlanUrl'
  | 'unitAmount'
  | 'upgradePlanUrl'
  | SubscriptionPlanModelKeySpecifier
)[];
export type SubscriptionPlanModelFieldPolicy = {
  availablePlans?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  entitlementAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  entitlementType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  interval?: FieldPolicy<any> | FieldReadFunction<any>;
  managePlanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  upgradePlanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionUsageModelKeySpecifier = (
  | 'id'
  | 'propertyUsage'
  | 'totalUsage'
  | SubscriptionUsageModelKeySpecifier
)[];
export type SubscriptionUsageModelFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  propertyUsage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalUsage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeSeriesResult_DecimalPoint_KeySpecifier = (
  | 'data'
  | 'interval'
  | TimeSeriesResult_DecimalPoint_KeySpecifier
)[];
export type TimeSeriesResult_DecimalPoint_FieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  interval?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeSeriesResult_IntegerPoint_KeySpecifier = (
  | 'data'
  | 'interval'
  | TimeSeriesResult_IntegerPoint_KeySpecifier
)[];
export type TimeSeriesResult_IntegerPoint_FieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  interval?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TopLevelMetricsResultKeySpecifier = (
  | 'bounceRate'
  | 'bounceRateChange'
  | 'bounceRatePrevious'
  | 'sessionDuration'
  | 'sessionDurationChange'
  | 'sessionDurationPrevious'
  | 'sessions'
  | 'sessionsChange'
  | 'sessionsPrevious'
  | 'visitors'
  | 'visitorsChange'
  | 'visitorsPrevious'
  | TopLevelMetricsResultKeySpecifier
)[];
export type TopLevelMetricsResultFieldPolicy = {
  bounceRate?: FieldPolicy<any> | FieldReadFunction<any>;
  bounceRateChange?: FieldPolicy<any> | FieldReadFunction<any>;
  bounceRatePrevious?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionDuration?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionDurationChange?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionDurationPrevious?: FieldPolicy<any> | FieldReadFunction<any>;
  sessions?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionsChange?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionsPrevious?: FieldPolicy<any> | FieldReadFunction<any>;
  visitors?: FieldPolicy<any> | FieldReadFunction<any>;
  visitorsChange?: FieldPolicy<any> | FieldReadFunction<any>;
  visitorsPrevious?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserProfileModelKeySpecifier = (
  | 'email'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'name'
  | 'profileUrl'
  | 'subscriptions'
  | 'timezone'
  | UserProfileModelKeySpecifier
)[];
export type UserProfileModelFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  profileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  AcceptInvitationResultModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AcceptInvitationResultModelKeySpecifier
      | (() => undefined | AcceptInvitationResultModelKeySpecifier);
    fields?: AcceptInvitationResultModelFieldPolicy;
  };
  AvailablePlanModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AvailablePlanModelKeySpecifier
      | (() => undefined | AvailablePlanModelKeySpecifier);
    fields?: AvailablePlanModelFieldPolicy;
  };
  ConversionCompletionModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversionCompletionModelKeySpecifier
      | (() => undefined | ConversionCompletionModelKeySpecifier);
    fields?: ConversionCompletionModelFieldPolicy;
  };
  ConversionModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversionModelKeySpecifier
      | (() => undefined | ConversionModelKeySpecifier);
    fields?: ConversionModelFieldPolicy;
  };
  DecimalPoint?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DecimalPointKeySpecifier
      | (() => undefined | DecimalPointKeySpecifier);
    fields?: DecimalPointFieldPolicy;
  };
  FunnelConversionsModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FunnelConversionsModelKeySpecifier
      | (() => undefined | FunnelConversionsModelKeySpecifier);
    fields?: FunnelConversionsModelFieldPolicy;
  };
  FunnelConversionsStepModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FunnelConversionsStepModelKeySpecifier
      | (() => undefined | FunnelConversionsStepModelKeySpecifier);
    fields?: FunnelConversionsStepModelFieldPolicy;
  };
  FunnelModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FunnelModelKeySpecifier
      | (() => undefined | FunnelModelKeySpecifier);
    fields?: FunnelModelFieldPolicy;
  };
  FunnelStepModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FunnelStepModelKeySpecifier
      | (() => undefined | FunnelStepModelKeySpecifier);
    fields?: FunnelStepModelFieldPolicy;
  };
  IntegerPoint?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IntegerPointKeySpecifier
      | (() => undefined | IntegerPointKeySpecifier);
    fields?: IntegerPointFieldPolicy;
  };
  ListEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ListEntryKeySpecifier
      | (() => undefined | ListEntryKeySpecifier);
    fields?: ListEntryFieldPolicy;
  };
  LoginResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LoginResultKeySpecifier
      | (() => undefined | LoginResultKeySpecifier);
    fields?: LoginResultFieldPolicy;
  };
  LoginResultModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LoginResultModelKeySpecifier
      | (() => undefined | LoginResultModelKeySpecifier);
    fields?: LoginResultModelFieldPolicy;
  };
  LookupValue?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LookupValueKeySpecifier
      | (() => undefined | LookupValueKeySpecifier);
    fields?: LookupValueFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Mutation_Account?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_AccountKeySpecifier
      | (() => undefined | Mutation_AccountKeySpecifier);
    fields?: Mutation_AccountFieldPolicy;
  };
  Mutation_Conversions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_ConversionsKeySpecifier
      | (() => undefined | Mutation_ConversionsKeySpecifier);
    fields?: Mutation_ConversionsFieldPolicy;
  };
  Mutation_Funnels?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_FunnelsKeySpecifier
      | (() => undefined | Mutation_FunnelsKeySpecifier);
    fields?: Mutation_FunnelsFieldPolicy;
  };
  Mutation_Images?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_ImagesKeySpecifier
      | (() => undefined | Mutation_ImagesKeySpecifier);
    fields?: Mutation_ImagesFieldPolicy;
  };
  Mutation_Profile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_ProfileKeySpecifier
      | (() => undefined | Mutation_ProfileKeySpecifier);
    fields?: Mutation_ProfileFieldPolicy;
  };
  Mutation_Properties?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_PropertiesKeySpecifier
      | (() => undefined | Mutation_PropertiesKeySpecifier);
    fields?: Mutation_PropertiesFieldPolicy;
  };
  Mutation_Subscriptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Mutation_SubscriptionsKeySpecifier
      | (() => undefined | Mutation_SubscriptionsKeySpecifier);
    fields?: Mutation_SubscriptionsFieldPolicy;
  };
  PropertyModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertyModelKeySpecifier
      | (() => undefined | PropertyModelKeySpecifier);
    fields?: PropertyModelFieldPolicy;
  };
  PropertySummaryModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertySummaryModelKeySpecifier
      | (() => undefined | PropertySummaryModelKeySpecifier);
    fields?: PropertySummaryModelFieldPolicy;
  };
  PropertyUsageModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PropertyUsageModelKeySpecifier
      | (() => undefined | PropertyUsageModelKeySpecifier);
    fields?: PropertyUsageModelFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  Query_Auth?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_AuthKeySpecifier
      | (() => undefined | Query_AuthKeySpecifier);
    fields?: Query_AuthFieldPolicy;
  };
  Query_Conversions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_ConversionsKeySpecifier
      | (() => undefined | Query_ConversionsKeySpecifier);
    fields?: Query_ConversionsFieldPolicy;
  };
  Query_Funnels?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_FunnelsKeySpecifier
      | (() => undefined | Query_FunnelsKeySpecifier);
    fields?: Query_FunnelsFieldPolicy;
  };
  Query_Lists?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_ListsKeySpecifier
      | (() => undefined | Query_ListsKeySpecifier);
    fields?: Query_ListsFieldPolicy;
  };
  Query_Lookups?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_LookupsKeySpecifier
      | (() => undefined | Query_LookupsKeySpecifier);
    fields?: Query_LookupsFieldPolicy;
  };
  Query_Metrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_MetricsKeySpecifier
      | (() => undefined | Query_MetricsKeySpecifier);
    fields?: Query_MetricsFieldPolicy;
  };
  Query_Profile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_ProfileKeySpecifier
      | (() => undefined | Query_ProfileKeySpecifier);
    fields?: Query_ProfileFieldPolicy;
  };
  Query_Properties?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_PropertiesKeySpecifier
      | (() => undefined | Query_PropertiesKeySpecifier);
    fields?: Query_PropertiesFieldPolicy;
  };
  Query_Series?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_SeriesKeySpecifier
      | (() => undefined | Query_SeriesKeySpecifier);
    fields?: Query_SeriesFieldPolicy;
  };
  Query_Subscriptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Query_SubscriptionsKeySpecifier
      | (() => undefined | Query_SubscriptionsKeySpecifier);
    fields?: Query_SubscriptionsFieldPolicy;
  };
  SubscriptionMemberModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionMemberModelKeySpecifier
      | (() => undefined | SubscriptionMemberModelKeySpecifier);
    fields?: SubscriptionMemberModelFieldPolicy;
  };
  SubscriptionModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionModelKeySpecifier
      | (() => undefined | SubscriptionModelKeySpecifier);
    fields?: SubscriptionModelFieldPolicy;
  };
  SubscriptionPlanModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionPlanModelKeySpecifier
      | (() => undefined | SubscriptionPlanModelKeySpecifier);
    fields?: SubscriptionPlanModelFieldPolicy;
  };
  SubscriptionUsageModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionUsageModelKeySpecifier
      | (() => undefined | SubscriptionUsageModelKeySpecifier);
    fields?: SubscriptionUsageModelFieldPolicy;
  };
  TimeSeriesResult_DecimalPoint_?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TimeSeriesResult_DecimalPoint_KeySpecifier
      | (() => undefined | TimeSeriesResult_DecimalPoint_KeySpecifier);
    fields?: TimeSeriesResult_DecimalPoint_FieldPolicy;
  };
  TimeSeriesResult_IntegerPoint_?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TimeSeriesResult_IntegerPoint_KeySpecifier
      | (() => undefined | TimeSeriesResult_IntegerPoint_KeySpecifier);
    fields?: TimeSeriesResult_IntegerPoint_FieldPolicy;
  };
  TopLevelMetricsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TopLevelMetricsResultKeySpecifier
      | (() => undefined | TopLevelMetricsResultKeySpecifier);
    fields?: TopLevelMetricsResultFieldPolicy;
  };
  UserProfileModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserProfileModelKeySpecifier
      | (() => undefined | UserProfileModelKeySpecifier);
    fields?: UserProfileModelFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
