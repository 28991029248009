import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { authStore } from './authStore';
import { refreshToken } from './refreshToken';

type UseAuthReturnValue = {
  isValid: boolean | undefined;
  isLoading: boolean;
};

export function useAuth(): UseAuthReturnValue {
  const { isLoading, isValid } = useSnapshot(authStore);

  useEffect(() => {
    refreshToken.resume();
    return () => refreshToken.pause();
  }, []);

  return {
    isValid,
    isLoading,
  };
}
